.tableBody {
  background-color: #ffffff;
}

.tableBody tr {
  border-bottom: 1px solid #e5e7eb;
}

/* tr styles */
.tableBody tr {
  padding: 0.25rem 1rem;
  background-color: #ffffff;
  transition: background-color 0.2s;
}

.tableBody tr:hover {
  background-color: #f9fafb;
}

/* td styles */
.tableBody td {
  padding: 24px;
  white-space: nowrap;
  text-align: left;
  font-size: 0.875rem;
  color: #4b5563;
}
