.pagination {
  /* 페이지네이션 컴포넌트의 스타일 */
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.pageItem a {
  /* 페이지 항목의 스타일 */
  cursor: pointer;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-right: 4px;
  padding: 0px 8px 0px 8px;
  background-color: #fff;
}
.pageItem a:hover {
  background-color: #e3e6e8;
  color: #3c3c3c;
}

.active a {
  /* 활성 상태의 스타일 */
  background-color: var(--color-brand);
  color: #fff;
}

.break {
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  border-radius: 3px;
  margin-right: 4px;
  padding: 0px 8px 0px 8px;
}

.previous {
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  border-radius: 3px;
  margin-right: 4px;
  padding: 0px 8px 0px 8px;
}
