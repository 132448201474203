.noContentBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.noContentBox p {
  font-family: "Pretendard Variable";

  font-size: 16px;
  color: var(--color-grey);
  margin-top: 8px;
}

.imagePreviewWrap {
  margin-top: 24px;

  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 16px;
  row-gap: 16px;

  overflow-x: auto;
}
.imagePreviewWrap img {
  border: 1px solid #d1d1d1;
  border-radius: 4px;

  width: 100px;
  height: 100px;
  object-fit: contain;
}

.imageContainer {
  position: relative;
  /* 기타 스타일 */
}

.checkIconWrap {
  position: absolute;
  bottom: 8px;
  right: 16px;
  margin: auto;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: var(--default-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkIcon {
  color: white;
}
.buttonWrap {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.deleteButton {
  background-color: #fbeef2;
  color: #d03362;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  transition: all 0.1s;
}
.deleteButton:hover {
  transform: scale(1.1);
}

.editButton {
  background-color: #f5eefb;

  color: var(--color-brand);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  transition: all 0.1s;
}

@media (max-width: 1200px) {
  .imagePreviewWrap {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 1000px) {
  .imagePreviewWrap {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 800px) {
  .imagePreviewWrap {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 600px) {
  .imagePreviewWrap {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 400px) {
  .imagePreviewWrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
