.button {
  background-color: var(--color-brand);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  width: 80px;
  /* padding: 8px 16px; */
  border-radius: 4px;
  min-width: fit-content;
}

.button:active {
  background-color: var(--color-activeBrand);
}
