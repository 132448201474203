.cancelButton span {
  font-family: Pretendard, sans-serif;
  font-size: 11px;
  line-height: 13px;
  font-weight: 700;
  word-break: keep-all;
  color: #d03362;
  text-decoration: underline #d03362;
  text-underline-offset: 4px;
  display: inline-block;
  transition: transform 0.2s;
}
.cancelButton:hover {
  transform: translateY(-2px);
}
.paybackButton,
.productButton {
  background-color: var(--color-brand);
  color: #fff;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  transition: all 0.1s;
}
.paybackButton:hover {
  transform: scale(1.1);
}
.productButton:hover {
  transform: scale(1.1);
}
.exSmallTh {
  width: 30px !important;
}
.smallTh {
  width: 50px !important;
}

.middleTh {
  width: 80px !important;
}

.countInputs {
  display: flex;
  align-items: center;
  gap: 10px;
}
.countInputs input {
  width: 30px;
  padding: 4px 8px;
  background-color: #f9fafb;
  border: 1px solid #d03338;
  border-radius: 4px;
}

.countInputs span {
  width: 30px;
  padding: 4px 8px;
  background-color: #f9fafb;
}

.editButton {
  width: 30px;
  cursor: pointer;
  color: var(--color-brand);
  font-size: 12px;
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 4px;
  padding: 4px;
}
