.wrap {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  font-weight: 700;
}

.input {
  width: 100%;
  margin-top: 8px;
}

.inputWrap {
  display: flex;
  align-items: center;
  gap: 16px;

  width: 100%;

  margin: 8px 0px;
}

.wrap input,
.wrap textarea {
  width: 100%;
  border: 1px solid #d1d1d1;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  border-radius: 6px;
  resize: vertical;
  padding: 8px;
  box-sizing: border-box;
}

.inputWrap label {
  flex-shrink: 0;
}

.inputWrap select {
  width: 100%;
  background-color: transparent;

  color: var(--color-brand);

  border: 1px solid var(--color-brand);
  border-radius: 12px;

  display: block;
  padding: 8px 0px;
  box-sizing: border-box;
}

.buttonInput {
  width: 100%;

  margin-bottom: 8px;

  cursor: pointer;
}

.buttonInput.actvie {
  background-color: var(--color-brand);
  color: #fff;
  font-weight: 500;
}

.active {
  background-color: var(--color-brand);
  color: #fff;
}

.fitButtonWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fitButton {
  color: var(--color-brand);
  border: 1px solid var(--color-brand);
  border-radius: 12px;

  padding: 4px 16px;
  box-sizing: border-box;
}
