.buttonWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin: 0 40px 60px 40px;
}

.buttonWrap button {
  background: #fddc3f;
  color: #383838;
  font-weight: 600;
  font-size: 14px;
  height: 44px;
  width: 100%;
  border-radius: 8px;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
