.wrap {
  display: flex;
  gap: 24px;
}

.categoryWrap {
  width: 240px;
  height: fit-content;
  min-height: 54px;
  max-height: 400px;

  background-color: #ffffff;
  color: #212121;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

.categoryWrap .setting {
  padding: 15px 24px 15px 26px;
  font-size: 14px;

  color: var(--color-brand);
  cursor: pointer;
}

.categoryWrap .categorList {
  font-size: 14px;
  padding-bottom: 12px;
}
.categoryWrap .categorList li {
  height: 40px;
  padding: 0px 16px;
  display: flex;
  align-items: center;

  cursor: pointer;
  justify-content: space-between;
}
.categoryWrap .categorList li .iconWrap {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.categoryWrap .categorList .active {
  background-color: var(--color-menu);
  color: #ffffff;
  /* color: var(--color-brand); */

  font-weight: 400;
}

.categoryWrap .categorList .total {
  border-bottom: 1px solid #dedede;
  margin-bottom: 8px;
}

.mainWrap {
  flex: 1;
}

.searchInputWrap {
  margin: 16px 0px;
}

.mainWrap main {
  margin-top: 24px;
}

@media (max-width: 1200px) {
  .wrap {
    flex-direction: column;
    gap: 0px;
  }

  .categoryWrap {
    width: 100%;
    margin-top: 48px;
  }
}
