.default-textarea {
  width: 100%;
  height: 100px;
  background: #2c2c2c;
  color: var(--default-font-color);
  padding: 8px;
  border: 1px solid var(--default-color);
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "Noto Sans KR-Regular";
  font-size: 15px;
}
