.container {
  display: flex;
  gap: 16px;
}
.previewContainer {
  flex: 1;

  padding: 16px;
  border-radius: 8px;
}

.editContainer {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
}
