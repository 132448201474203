.editSection {
  display: flex;
  gap: 16px;
}
.editSection .previewListWrap {
  flex: 2;
  width: 750px;
}

.editSection .previewListWrap .previewWrap {
  background: var(--dark-bg-color);
  border-radius: 10px;

  width: 100%;
  height: 475px;
}

.listWrap {
  border-radius: 12px;

  display: flex;
  gap: 8px;

  overflow-x: auto;
  padding: 8px;

  box-sizing: border-box;
  width: 100%;

  height: 191px;

  background: var(--dark-bg-color);

  margin-top: 24px;
}

.listWrap img.active {
  border: 4px solid var(--default-color);
}

.listWrap .reorderActive {
  border: 4px solid #fddc3f;
}

.imageWrap {
  position: relative;
  width: 100%;

  height: auto;
  display: flex;
}

.imageWrap .iconWrap {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  margin: auto;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: var(--default-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderWrap {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  background-color: var(--default-color);
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}
.rateWrap {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;

  color: white;
  font-size: 0.75rem;
  font-weight: 600;
}

.checkIcon {
  color: white;
}

.editSection .funcWrap {
  flex: 1;
  background: var(--dark-bg-color);
  border-radius: 10px;

  height: 684px;
}

.buttonWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 16px;
  margin-top: 24px;
}

.buttonWrap button {
  background-color: var(--color-brand);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  height: 36px;
  width: 300px;
  /* padding: 8px 16px; */
  border-radius: 4px;
  min-width: fit-content;
}

.buttonWrap button.cancel {
  background-color: var(--color-grey);
  color: var(--color-text);
}

@media (max-width: 768px) {
  .mobileContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1e1e1e;
    padding: 16px;
    padding-top: 24px;
  }
  .mobileWrap {
    height: 100vh;
    overflow-y: auto;
  }
  .listWrap {
    height: fit-content;
  }

  .listWrap img.previewImg {
    width: 178px;
    height: 99px;
    object-fit: contain;
    background-color: #000;
  }
  .editSection {
    padding-bottom: 100px;
  }

  .editSection .previewListWrap {
    width: 100%;
  }

  .editSection .funcWrap {
    margin-top: 16px;
    height: fit-content;
    margin-bottom: 12px;
    background-color: #1e1e1e;
  }

  .editSection .previewListWrap .previewWrap {
    height: auto;
    background-color: transparent;
  }
}
