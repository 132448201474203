:root {
  --maxwidth: 1366px;

  --default-color: #339dff;

  --button-main-color: #339dff;
  --button-dark-color: #535353;

  --main-bg-color: #101010;
  /* --main-bg-color: #1e1e1e; */
  --dark-light-bg-color: #2c2c2c;
  --dark-bg-color: #373737;
  --modal-bg-color: #484848;

  --disable-bg-color: #dee4e9;

  /* font */
  --default-font-style: "Noto Sans KR-Regular";
  --default-font-color: #ffffff;
  --error-font-color: #f93232;
  --light-font-color: #d8d8d8;
  --disable-font-color: #98a7b1;

  /* 어드민 */
  --color-brand: #4f46e5;
  --color-bg-dark: #f3f3f3;
  --color-bg: #f3f4f6;
  --color-grey: #d1d1d1;
  --color-text: #22243b;
  --color-accent: #0091fb;
  --color-white: #fff;
  --color-scrollbar: #aaa7a7;
  /* --color-brand: #1890ff; */
  --color-activeBrand: #1268a5;
  --color-header: #062441;
  --color-warning: #ff4d4f;
  --color-menu: #1f2d37;
  --color-body: #f5f5f5;

  --color-processing: #ffc14e;
  --color-premaking: #2b7df7;
  --color-making: #fd5b8c;
  --color-confirm: #b9c834;
  --color-premodify: #40bcef;
  --color-modify: #ffc14e;
  --color-modifying: #c958f7;
  --color-done: #5d7f45;
}

/* #edb035; */
