/* :root {
  --color-brand: 
  --color-bg-dark: #f3f3f3;
  --color-bg: #f3f3f3;
  --color-grey: #d1d1d1;
  --color-text: #22243b;
  --color-accent: #0091fb;
  --color-white: #fff;
  --color-scrollbar: #aaa7a7;
} */

/* default */
@import "./variable.css";
@import "./reset.css";
@import "./font.css";

/* page */
@import "./pages/service.css";
@import "./pages/admin.css";
@import "./pages/home.css";
@import "./pages/shop.css";

/* components */
@import "./components/ui/index.css";
@import "./components/service/index.css";

html {
  background-color: var(--main-bg-color);
}
/*
html.dark {
  --color-bg-dark: #1a1c35;
  --color-bg: #22243b;
  --color-grey: #4e4e4e;
  --color-text: #fdfffd;
} */

#app {
  width: 100%;
  height: 100vh;

  padding-top: 40px;

  padding-bottom: 186px;

  font-family: var(--default-font-style);
}

.service-layout {
  background-color: var(--main-bg-color);
  max-width: 500px;
  margin: auto;
  color: var(--default-font-color);
  height: auto !important;
  padding-bottom: 100px !important;
}
.shop-layout {
  max-width: 500px;
  margin: auto;
  padding: 0px !important;
}

.admin-layout {
  background-color: #f3f4f6; /* 어드민 경로에 대한 배경색 */

  /* 어드민 레이아웃에 대한 추가 스타일 */
}

#app.default-layout {
  background-color: white; /* 기본 배경색 */
  /* 기본 레이아웃에 대한 추가 스타일 */
  padding-top: 0px;
  height: auto;
  padding-bottom: 0;
  overflow-x: hidden;
}

.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* loading css */
#splash-loading {
  background-image: url("../images/service/loading_image.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: var(--main-bg-color);
  width: 100%;
  max-width: 600px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  animation: load 2s ease-in-out;
}
#splash-loading img {
  transform: scale(0.5);
}

#splash-loading .loading-img {
  animation: dessolveIn 0.5s ease-in-out;
  transform: scale(0.5);
}

@keyframes load {
  0% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes dessolveIn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#splash-loading.hidden {
  display: none;
}

@media (max-width: 1200px) {
}
