#shops-page {
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;

  height: 100vh;
  margin: auto;
}

#shops-page main {
  margin-top: 96px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

#shops-page main .shop-card {
  color: #fff;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;

  cursor: pointer;
}

#shops-page main .shop-card img {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;

  margin-bottom: 16px;
}

#shops-page main .shop-card h6 {
  font-weight: 500;
  line-height: 130%;

  margin-bottom: 8px;
}

#shops-page main .shop-card span {
  font-weight: 700;
  line-height: 140%; /* 22.4px */
}

#premium-page {
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;

  height: 100vh;
  margin: auto;

  background-image: url(../../images/shop/main_bg.png);
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

#premium-page .title_box {
  display: flex;
  flex-direction: column;
  gap: 32px;

  align-items: center;
}

#premium-page .title_box .border_line {
  width: 0px;
  height: 183px;
  flex-shrink: 0;

  border: 1px solid #fff;
}

#premium-page .title_box h4 {
  color: #fff;
  text-align: center;
  font-family: "Noto Sans CJK KR";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

#premium-page .bottom_btn {
  max-width: 478px;
  height: 48px;
  flex-shrink: 0;

  border-radius: 10px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(2px);

  position: fixed;
  bottom: 48px;
  right: 16px;
  left: 16px;
  margin: auto;

  box-sizing: border-box;
}

#premium-page .bottom_btn span {
  color: #fff;
  text-align: center;

  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
}
.overlay {
  display: none;
}
.overlay.hideWrap {
  display: block;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
}

/* 상품 상세 페이지 */
#shop-detail-page {
  max-width: 500px;

  height: fit-content;
  min-height: 100vh;
  margin: auto;
  padding-top: 60px;
}

#shop-detail-page.hide {
  overflow: hidden;
}

#shop-detail-page .thum_wrap {
  /* background-image: url(../../images/shop/usbbox_bg.png);
  background-size: contain; */
  height: 550px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-sizing: border-box;
}

#shop-detail-page .thum_wrap img {
  width: 100%;
  height: 550px;
  object-fit: cover;
}

#shop-detail-page .thum_wrap div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#shop-detail-page .thum_wrap span,
#shop-detail-page .thum_wrap h2,
#shop-detail-page .thum_wrap h6 {
  text-align: center;
  font-family: "Noto Sans CJK KR";
  line-height: 130%;

  background: linear-gradient(90deg, #fff 0%, #999 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#shop-detail-page .thum_wrap span {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.5px;
}

#shop-detail-page .thum_wrap h2 {
  font-size: 20px;
  font-weight: 700;
}

#shop-detail-page .thum_wrap h6 {
  font-size: 18px;
  font-weight: 500;
}
#shop-detail-page .detail_wrap {
  padding: 0px;
  padding-bottom: 140px;
}
#shop-detail-page .detail_wrap ul.list {
  display: flex;
  gap: 24px;
  padding: 40px 24px 32px 24px;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  position: sticky;
  top: 0;

  background-color: #151515;
}

#shop-detail-page .detail_wrap ul.list li {
  color: #a9a9a9;
  font-family: "Noto Sans CJK KR";
  font-size: 15px;

  font-weight: 400;
  line-height: 130%;

  padding: 0px 12px;

  cursor: pointer;
}

#shop-detail-page .detail_wrap ul.list li.active {
  color: #fff;
  font-weight: 500;
}

#shop-detail-page .detail_wrap .detail-page_img {
  /* margin-top: 24px; */
  position: relative;
}

#shop-detail-page .detail_wrap .detail-page_img .sample_video {
  width: 100%;
  height: 265px; /* 기본 높이 */
  border-radius: 10px 10px 0px 0px;
  background: #000;

  position: absolute;
  top: calc(24.5%);
}

#shop-detail-page .detail_wrap .detail-page_img .short_sample_video {
  width: 100%;
  height: 265px; /* 기본 높이 */
  border-radius: 10px 10px 0px 0px;
  background: #000;

  position: absolute;
  top: calc(39.7%);
}
#shop-detail-page .detail_wrap .detail-page_img img {
  width: 100%;
}

#shop-detail-page .bottom-order_section {
  width: 100%;
  max-width: 500px;
  padding: 24px 16px 48px 16px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #222;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0;
  transition: bottom 0.3s ease-in-out; /* 애니메이션 효과 */
  z-index: 999;
}

#shop-detail-page .bottom-order_section.hide {
  bottom: -100%;
  transition: bottom 0.5s ease-in-out; /* 애니메이션 효과 */
}

#shop-detail-page .bottom-order_section .info_wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  color: #fff;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
}

#shop-detail-page .bottom-order_section .info_wrap .price-info_wrap {
  display: flex;
  align-items: center;
  gap: 8px;
}

#shop-detail-page .bottom-order_section .info_wrap .price-info_wrap span {
  color: var(--default-color);

  font-weight: 700;
  line-height: 140%;
}
#shop-detail-page .bottom-order_section .info_wrap .price-info_wrap p {
  font-size: 18px;

  font-weight: 700;
  line-height: 140%;
}

#shop-detail-page .bottom-order_section .buy_btn {
  display: flex;
  width: 180px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 6px;
  background: #107de2;
}
#shop-detail-page .bottom-order_section .buy_btn span {
  color: #fff;
  text-align: center;

  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
}

#shop-detail-page .bottom-order_section.free-shop_section {
  justify-content: center;
  gap: 8px;

  box-sizing: border-box;
  padding-bottom: 24px;
}
#shop-detail-page .bottom-order_section.free-shop_section button {
  width: 100%;
  flex-shrink: initial;
}

#shop-detail-page .bottom-order_section.free-shop_section .back_btn {
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 6px;
  background: #d1d1d1;
}

#shop-detail-page .bottom-order_section.free-shop_section .back_btn span {
  color: #303030;
  text-align: center;

  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
}

#shop-detail-page .bottom-order_section.expand-order_section {
  flex-direction: column;
  padding: 32px 16px;
  gap: 24px;
}

#shop-detail-page
  .bottom-order_section.expand-order_section
  .option-select_wrap {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid #339dff;
  color: #339dff;
  cursor: pointer;
}

#shop-detail-page
  .bottom-order_section.expand-order_section
  .option-select_wrap
  p {
  color: #fff;
  font-family: "Noto Sans CJK KR";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 19.5px */
}

#shop-detail-page
  .bottom-order_section.expand-order_section
  .option-select-box_wrap {
  width: 100%;
  position: relative;
}
#shop-detail-page .bottom-order_section.expand-order_section .select-box_wraps {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 0px 0px 8px 8px;
  border: 1px solid #339dff;
  border-top: none;
  background: #303030;

  position: absolute;

  box-sizing: border-box;

  z-index: 999;
}

#shop-detail-page
  .bottom-order_section.expand-order_section
  .select-box_wraps
  div {
  display: flex;
  height: 56px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-bottom: 1px solid #878787;

  cursor: pointer;
}

#shop-detail-page
  .bottom-order_section.expand-order_section
  .select-box_wraps
  div
  p {
  color: #fff;
  font-family: "Noto Sans CJK KR";
  font-size: 15px;

  font-weight: 500;
  line-height: 130%;
}

#shop-detail-page .bottom-order_section.expand-order_section .option-box_wraps {
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 24px;
}

#shop-detail-page .bottom-order_section.expand-order_section .option-box_wrap {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  background: #323232;
}
#shop-detail-page
  .bottom-order_section.expand-order_section
  .option-box_wrap
  .box_wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  position: relative;
}

#shop-detail-page
  .bottom-order_section.expand-order_section
  .option-box_wrap
  .box_wrap
  .close-btn
  svg {
  width: 24px;
  height: 24px;
  color: #fff;
  position: absolute;
  top: -8px;
  right: -8px;
}

#shop-detail-page
  .bottom-order_section.expand-order_section
  .option-box_wrap
  p {
  width: 100%;
  color: #fff;
  font-family: "Noto Sans CJK KR";
  font-size: 15px;
  font-weight: 500;
  line-height: 130%;
}

#shop-detail-page
  .bottom-order_section.expand-order_section
  .option-box_wrap
  p:last-child {
  text-align: end;
}

#shop-detail-page
  .bottom-order_section.expand-order_section
  .option-box_wrap
  .quantity_wrap {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 16px;

  border-radius: 4px;
  border: 1px solid #959595;
  background: #494949;
}

#shop-detail-page
  .bottom-order_section.expand-order_section
  .option-box_wrap
  .quantity_wrap
  span {
  color: #fff;

  font-family: "Noto Sans CJK KR";
  font-size: 15px;

  font-weight: 500;
  line-height: 130%;
}
#shop-detail-page
  .bottom-order_section.expand-order_section
  .option-box_wrap
  .quantity_wrap
  svg {
  width: 20px;
  height: 20px;
  color: #fff;

  cursor: pointer;
}

#shop-detail-page .bottom-order_section.expand-order_section .border-line {
  width: 100%;
  border-top: 1px solid #565656;
}
#shop-detail-page .bottom-order_section.expand-order_section .info_wrap {
  width: 100%;
}
#shop-detail-page .bottom-order_section.expand-order_section .price-info_wrap {
  width: 100%;
  justify-content: space-between;
}
#shop-detail-page
  .bottom-order_section.expand-order_section
  .price-info_wrap
  span {
  color: #fff;
}
#shop-detail-page .bottom-order_section.expand-order_section .buy_btn {
  width: 100%;
}

/* 상품 주문 페이지 */
#shop-order-page {
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 150px;

  height: fit-content;
  min-height: 100vh;
  margin: auto;
  background-color: #fff;
}

#shop-order-page header {
  padding: 64px 0px 16px 0px;
  border-bottom: 3px solid black;
}
#shop-order-page .order-section {
  border-bottom: 3px solid black;
  padding: 24px 0px;
}
#shop-order-page .order-section.last-section {
  border-bottom: none;
}
#shop-order-page .order-title_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#shop-order-page .order-title_wrap h4 {
  font-family: "Noto Sans KR-Bold";
  font-weight: 700;
  font-size: 18px;
}

#shop-order-page .order-title_wrap .order-price_wrap {
  display: flex;
  align-items: center;
  gap: 12px;
}

#shop-order-page .order-title_wrap .order-price_wrap span {
  color: var(--default-color);
}
#shop-order-page .order-title_wrap .order-price_wrap span b {
  font-family: "Noto Sans KR-Bold";
  font-size: 24px;
}
#shop-order-page .order-subtitle {
  font-family: "Noto Sans KR-Mideum";
  font-size: 15px;
  line-height: 130%;
  margin-bottom: 24px;
}

#shop-order-page .order-deil-input_wraps {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
}

#shop-order-page .order-deil-input_wrap,
#shop-order-page .order-deil-select_wrap {
  font-family: "Noto Sans KR-Regular";
  display: flex;
  align-items: center;
  gap: 24px;
}

#shop-order-page .phone-input_wrap > div {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #e1e1e1;
}

#shop-order-page .order-deil-input_wrap label.required::after,
#shop-order-page .order-deil-select_wrap label.required::after {
  content: "*";
  display: inline;
  color: var(--default-color);
  margin-left: 2px;
  vertical-align: middle;
}
#shop-order-page .order-deil-input_wrap label,
#shop-order-page .order-deil-select_wrap label {
  min-width: 60px;
  width: 70px;
  flex-shrink: 0;
  color: #4b4b4d;
  font-size: 16px;
}
#shop-order-page .order-deil-input_wrap input,
#shop-order-page .order-deil-select_wrap select {
  border: 1px solid #d1d1d1;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 14px;

  resize: vertical;
  padding: 12px;
  box-sizing: border-box;
}
#shop-order-page .order-deil-select_wrap {
  position: relative;
}
#shop-order-page .order-deil-select_wrap::after {
  content: "";
  position: absolute;
  right: 16px;
  top: 32%;

  pointer-events: none;
  border: solid #69696b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
#shop-order-page .order-deil-select_wrap select {
  color: #69696b;
  -moz-appearance: none; /* 파이어폭스에서 기본 화살표 제거 */
  -webkit-appearance: none; /* 크롬, 사파리에서 기본 화살표 제거 */
  appearance: none; /* 최신 브라우저에서 기본 화살표 제거 */
}

#shop-order-page .addr-input_wrap > div {
  display: flex;
  align-items: center;
  gap: 8px;
}
#shop-order-page .order-deil-input_wrap .postcode-btn {
  width: 100%;
  max-width: 80px;
  padding: 12px 0px;
  font-size: 12px;
  color: #69696b;
  background-color: #f4f4f4;
}

#shop-order-page .order-bottom-btn {
  font-family: "Noto Sans KR-Medium";
  font-size: 16px;

  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 16px 0px 24px;

  background-color: var(--default-color);
  color: #fff;
}

#shop-order-page .order-shop-info_wrap {
  border-top: 1px solid #ebebeb;
  margin-top: 24px;
  padding: 16px 0px;
  display: flex;

  justify-content: space-between;
  align-items: center;
}

#shop-order-page .order-shop-info_wrap h6 {
  font-size: 13px;
  color: #373738;
  margin-bottom: 8px;
}

#shop-order-page .order-shop-info_wrap p.order-shop-info_desc {
  margin-bottom: 16px;
  font-family: "Noto Sans KR-Bold";
  font-size: 14px;
}
#shop-order-page .order-shop-info_wrap p.order-shop-info_option {
  margin-bottom: 8px;
  color: #373738;
  font-family: "Noto Sans KR-Regular";
  font-size: 13px;
}
#shop-order-page .order-shop-info_wrap p.order-shop-info_price {
  margin-bottom: 16px;
  color: var(--default-color);
  font-family: "Noto Sans KR-Medium";
  font-size: 14px;
  line-height: 1.45;
}

#shop-order-page .order-shop-info_wrap img {
  width: 100px;
  height: 90px;
  object-fit: cover;
}

#shop-order-page .order-price-info_wrap {
  border-top: 1px solid #ebebeb;
  margin-top: 24px;
  padding: 24px 0px 40px 0px;
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: center;
}

#shop-order-page .order-price-info_wrap .price_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#shop-order-page .order-price-info_wrap .price_wrap h6 {
  color: #000;
  text-align: center;
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 8px;
}

#shop-order-page .order-price-info_wrap .price_wrap span {
  color: #000;
  text-align: center;
  font-family: "Noto Sans KR";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 18px */
}

#shop-order-page .order-section .info_wrap p {
  color: #8f8f8f;
  font-family: "Noto Sans KR";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
}

#shop-order-page .order-pay-methods_wrap {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  margin-top: 24px;
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

#shop-order-page .order-pay-methods_wrap > div {
  display: flex;
  gap: 8px;
}
#shop-order-page .order-pay-methods_wrap button {
  border: 1px solid #d1d1d1;
  width: 100%;
  padding: 16px;
}

#shop-order-page .order-pay-methods_wrap button.active {
  background-color: #000;
  color: #fff;
  border: none;
}

#shop-order-page .order-agree_wrap {
  margin-top: 24px;
  display: flex;
  gap: 8px;
}
#shop-order-page .order-agree_wrap label {
  color: #69696b;
  line-height: 1.4;
}

@media (max-width: 500px) {
  #shop-detail-page .detail_wrap .detail-page_img .sample_video {
    height: calc(53vw);
  }

  #shop-detail-page .detail_wrap .detail-page_img .short_sample_video {
    height: calc(53vw);
  }
}
