@font-face {
  font-family: "Noto Sans KR-Light";
  font-style: normal;
  font-weight: 300;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR-Regular";
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR-Medium";
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR-Bold";
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf)
      format("opentype");
}

@font-face {
  font-family: "MapoFlowerIsland";
  src: url("../fonts/MapoFlowerIsland.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Hardworking";
  src: url("../fonts/Hardworking.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Grandpa_sharing";
  src: url("../fonts/Grandpa_sharing.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Daughter_handwriting";
  src: url("../fonts/Daughter_handwriting.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Cafe24Oneprettynight";
  src: url("../fonts/Cafe24Oneprettynight.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MapoAgape";
  src: url("../fonts/MapoAgape.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.head-title {
  font-weight: 700;
  line-height: 140%;
  font-weight: bold;
  color: var(--default-font-color);
}
.head-title.big {
  font-size: 24px;
}
.head-title.x-large {
  font-size: 20px;
}
.head-title.large {
  font-size: 18px;
}
.head-title.medium {
  font-size: 16px;
}
.head-title.medium_15 {
  font-size: 15px;
}
.head-title.small {
  font-size: 14px;
}

.body-title {
  font-weight: 400;
  /* line-height: 130%; */
}
.body-title.bold {
  font-weight: 700;
}

.body-title.large {
  font-size: 18px;
}
.body-title.medium {
  font-size: 16px;
}
.body-title.small {
  font-size: 14px;
}
.body-title.small_13 {
  font-size: 13px;
}
.body-title.x-small {
  font-size: 12px;
}

/* 상단 큰 타이틀 스타일 */
.basic-title__wraps .body-title {
  font-family: "Noto Sans KR-Bold";
  line-height: 160%;
}
