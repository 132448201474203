.funcSection {
  margin-top: 16px;
}

.searchWrap {
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 12px;
}
.searchWrap form {
  flex: 1;
}

.mainWrap {
  margin-top: 24px;
}

.summaryContainer {
  background-color: #fff;
  border: 1px solid #e3e7ee;
  overflow-x: auto;
}

.summaryContainer .list {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  min-width: 500px;
}

.summaryContainer .list button {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Noto Sans KR-Regular";
}

.active {
  color: var(--color-brand);
}

.summaryContainer .list .icon {
  border-radius: 50%;
  background-color: #1f2d37;
  width: 42px;
  height: 42px;

  color: #fff;
  font-size: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.summaryContainer .list button.active .icon {
  background-color: var(--color-brand);
}

.summaryContainer .list b {
  font-size: 22px;
  font-family: "Noto Sans KR-Bold";
  font-weight: normal;
  margin-right: 4px;
}
