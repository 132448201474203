.head-title-desc {
  width: 100%;
  max-width: 500px;
  margin: auto;
  text-align: center;
}
.head-title-desc p {
  margin-top: 10px;
  color: var(--light-font-color);
}
.head-title-desc p.linkText {
  margin-top: 16px;
  color: var(--default-color);
}
.head-title-desc p.linkText::after {
  background: url(../../../images/service/chevron-right-blue.svg) no-repeat 0px
    0px;
  content: " ";

  display: inline-block;
  width: 13px;
  height: 13px;
  margin-left: 8px;
}

.basic-title__wraps {
  margin-top: 52px;
}

.basic-title-desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 0px 16px;
  margin-bottom: 16px;
  background-color: #2c2c2c;
  padding: 24px;
  border-radius: 10px;
}

.basic-title-desc .title-wrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: "Noto Sans KR-Bold";
}
.basic-title-desc .title-wrap .step-title {
  font-size: 16px;
  color: var(--default-color);
}
.basic-title-desc .body-title {
  font-family: "Noto Sans KR-Light";
}
.basic-title-desc h6 {
  font-size: 20px;
}
.basic-title-desc img {
  width: 65px;
}
.basic-title-desc p {
  color: var(--light-font-color);
  font-size: 15px;
  white-space: pre-line;
  margin-top: 12px;
  line-height: 130%;
}

.error-text {
  padding-top: 8px;
  color: var(--error-font-color);
}
