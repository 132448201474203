.mainWrap {
  margin-top: 24px;
  gap: 16px;
  display: flex;
}

.mainWrap .infoWrap {
  width: 100%;
  flex: 2.5;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mainWrap .videoWrap {
  width: 100%;
  height: 500px;
  flex: 1;
}

.infoTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-brand);
  white-space: nowrap;
}

.basicInfoWrap {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.basicInfoWrap > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.basicInfoWrap > div .titleWrap {
  flex: 1;
  width: 100%;
}

.basicInfoWrap > div .titleWrap h6 {
  font-size: 14px;
  color: #b0b4bc;
  margin-bottom: 8px;
}

.imageUploadWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imageUploadWrap p {
  margin-top: 4px;
  font-size: 14px;
  color: #6b7280;
}

.desc {
  margin-top: 4px;
  font-size: 14px;
  color: #6b7280;
}

.imageUploadWrap button {
  background-color: var(--color-brand);

  color: #fff;
  font-weight: bold;

  width: 80px;
  height: 30px;

  border-radius: 8px;
}

.mainWrap .videoWrap article {
  height: 100%;
}

.mainWrap .videoWrap .contentWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
}
.mainWrap .videoWrap .contentWrap > div {
  flex: 1;
  height: 100%;
}

.videoWrap button {
  border: 1px solid var(--color-brand);

  color: var(--color-brand);
  font-weight: bold;

  width: 100%;
  height: 40px;

  border-radius: 8px;

  margin-top: 10px;
}

.videoWrap button a {
  color: var(--color-brand);
}

.previewWrap {
  width: 350px;
}
.previewWrap iframe {
  margin-top: 8px;

  width: 350px;
  height: 185px;
  max-width: 500px;
  background: #000;
}

.exSmallTh {
  width: 30px !important;
}

.smallTh {
  width: 60px !important;
}

.middleTh {
  width: 80px !important;
}

@media (max-width: 1200px) {
  .mainWrap {
    flex-direction: column-reverse;
  }
}
