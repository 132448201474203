@import "./ui.css";
@import "./upload-image.css";
@import "./upload-text.css";

.service-main_btn {
  width: 90%;
  max-width: 500px;
  height: 48px;

  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0px;
  margin: auto;
  border-radius: 10px;

  background-color: var(--button-main-color);
  color: #ffffff;
}
.service-main_btn.disable {
  background-color: var(--disable-bg-color);
}
.service-main_btn.disable h6 {
  color: var(--disable-font-color);
}

.default-header {
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  max-width: 500px;
  padding: 40px 16px 0px 16px;
  z-index: 999;
  background: var(--main-bg-color);
  font-family: "Noto Sans KR-Regular";
}

.default-header.all-feedbacks {
  padding-bottom: 16px;
}

.default-header button {
  color: #fff;
  font-size: 18px;
  width: 18px;
}

.default-header button.help-icon_btn {
  color: var(--default-color);
}

.share-button {
  width: 108px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;

  border: 1px solid var(--button-main-color);
  border-radius: 4px;
  color: var(--button-main-color);
}

.share-button span {
  font-weight: 700;
  font-size: 14px;
}

.share-modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  z-index: 9999;
  cursor: pointer;
}

.share-modal main {
  position: absolute;
  top: 10rem;
  margin: 1rem;
  padding: 2.5rem 2rem;
  width: 75%;
  max-width: 250px;
  height: auto;
  background-color: var(--modal-bg-color);
  border-radius: 0.75rem;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 30;
}

.share-modal main h4 {
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 1.125rem;
  margin-bottom: 1.75rem;
}

.share-modal main .close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
}

.share-modal main .buttons {
  display: flex;
  gap: 1rem;
}

.share-modal main .buttons button {
  width: 100%;
  height: 2rem;
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.share-modal main .kakao-button {
  background-color: #ffe812;
  color: #3d1d1c;
}

.share-modal main .copy-link-button {
  background-color: #f7fbff;
  color: #484848;
}

.share-modal main .buttons span {
  font-weight: bold;
  font-size: 0.875rem;
  flex-shrink: 0;
}

.share-modal main p {
  margin-top: 1.5rem;
  text-align: center;
  color: var(--default-color);
}

.edit-function_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  color: #fff;
}
.edit-function_btn .image_wraps.edit {
  width: 48px;
  height: 48px;
  background-color: var(--button-dark-color);

  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-function_btn.checked .image_wraps {
  background-color: var(--button-main-color);
}

.slide_container {
  width: 100%; /* Width of the outside container */
  position: relative;
  margin: auto;
}
.slide_container .line {
  width: 16px;
  height: 0px;
  border: 2px solid #dcdcdc;
  transform: rotate(90deg);
  position: absolute;
  left: 0;
  right: 0;
  top: 6px;
  bottom: 0;
  margin: auto;
  z-index: -1;
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%;
  height: 4px;
  background: #777777;

  outline: none; /* Remove outline */
  opacity: 1;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 14px;
  height: 14px;
  background: var(--button-dark-color);
  border: 2px solid var(--default-color);
  border-radius: 100px;
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 14px;
  height: 14px;
  background: var(--button-dark-color);
  border: 2px solid var(--default-color);
  border-radius: 100%;
  cursor: pointer; /* Cursor on hover */
}
