.calendarWrap {
  position: relative;
}
.calendarWrap .previewButton {
  display: inline-flex;
  gap: 16px;

  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;

  height: 40px;
  padding: 0px 16px;

  -webkit-user-select: none;
  user-select: none;
  transition: transform 0.2s;
  white-space: nowrap;

  color: #a7a7a7;
  font-family: Pretendard, sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  word-break: keep-all;

  border: 1px solid #eaeaea;
  background-color: #fff;

  color: #000;
  border: 1.5px solid #000000;
}

.calendarIcon {
  font-size: 18px;
  color: var(--color-brand);
}

.datePickerWrap {
  position: absolute;
  left: 0;
  top: 2.5rem;
  z-index: 50;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
