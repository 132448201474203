#outer-container {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

#page-wrap {
  padding: 1.5rem 1rem;
  min-height: 100%;
  overflow-y: auto;
  flex: 1 1;
}
#page-wrap > div {
  max-width: 1280px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  #page-wrap {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1024px) {
  #page-wrap {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
