.titleCountCards {
  display: flex;

  gap: 16px;
}
.titleCountCards > div {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 16px;
  width: 100%;
}

.renderCountCards {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 16px;
  width: 100%;
}

.chartWraps {
  display: flex;
  gap: 24px;
  overflow-x: auto;
}

.chartWrap {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 10px;
  background: #fff;
}

.chartWrap .titleWrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chartWrap .titleWrap ul {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chartWrap .titleWrap li {
  color: #212121;

  font-family: "Noto Sans KR";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 106.667% */
  cursor: pointer;

  padding: 8px 10px;
}
.chartWrap .titleWrap li.active {
  color: #339dff;
  border-radius: 4px;
  background: #f0f8ff;
}
.chartWrap .titleWrap span {
  color: #383838;
  font-family: "Noto Sans KR";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 106.667% */
}

.section {
  margin-top: 18px;
}

.section h4 {
  font-family: "Noto Sans KR-Bold";
  font-size: 18px;
  margin-bottom: 8px;

  line-height: 1.75;
  color: #2d3748;
}

.eventTableWrap {
  background-color: #fff;

  border-radius: 12px;
  width: 100%;

  padding: 16px 24px;
  box-sizing: border-box;
}

.eventTableWrap form {
  padding-left: 0px;
}

.eventTableWrap input {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
  border-radius: 12px 0px 0px 12px !important;
  padding-left: 12px !important;
}

.uid {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.smallTh {
  width: 50px !important;
}

.cancelButton span {
  font-family: Pretendard, sans-serif;
  font-size: 11px;
  line-height: 13px;
  font-weight: 700;
  word-break: keep-all;
  color: #d03362;
  text-decoration: underline #d03362;
  text-underline-offset: 4px;
  display: inline-block;
  transition: transform 0.2s;
  cursor: pointer;
}
.cancelButton:hover {
  transform: translateY(-2px);
}
.detailsButton {
  background-color: #f5eefb;
  color: var(--color-brand);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  transition: all 0.1s;
}

@media (max-width: 1200px) {
  .titleCountCards {
    flex-wrap: wrap;
    gap: 0px;
  }

  .renderCountCards {
    flex-direction: column;
    gap: 8px;
  }
}
