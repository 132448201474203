.serviceTemplateCheck {
  /* margin-top: 36px; */
  margin-bottom: 80px;
}

/* 버튼 */
.typeNav {
  font-family: "Noto Sans KR-Medium";
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--main-bg-color);
}
.typeNav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 10px;
}
.typeNav li {
  width: 100%;
  max-width: 170px;
  text-align: center;
  font-family: "Noto Sans KR-Regular";
  font-size: 15px;
  padding: 16px 0;
  color: #a9a9a9;
}
.typeNav li.active {
  color: #fff;
  border-bottom: 1px solid #f5f5f7;
}

/* 서비스 정보 - 첫번째 섹션 */
.videoPreview {
  margin: 0 16px;
}
.videoPreview .title {
  font-family: "Noto Sans KR-Bold";
  font-size: 24px;
  line-height: 140%;
  color: #86868b;
  padding: 24px 8px;
}
.videoPreview .title span {
  color: #f5f5f7;
}
.videoPreview section > div {
  background-color: #262626;
  font-family: "Noto Sans KR-Bold";
  line-height: 140%;
  color: #f5f5f7;
  border-radius: 10px;
  padding: 24px;
  margin-top: 24px;
}
.videoPreview section h3 {
  color: #ffd900;
}
.videoPreview section h2 {
  font-size: 24px;
  margin: 8px 0 24px 0;
}
.videoPreview section h4 {
  font-family: "Noto Sans KR-Mideum";
  font-size: 15px;
  color: #d8d8d8;
}
.videoPreview section button {
  width: 100%;
  padding: 16px 18px;
  border-radius: 8px;
  background-color: #214369;
  font-family: "Noto Sans KR-Mideum";
  font-weight: 600;
  font-size: 16px;
  color: var(--default-color);
  margin-top: 24px;
}
.videoPreview section p {
  font-family: "Noto Sans KR-Light";
  font-size: 14px;
}
.videoPreview section span {
  font-family: "Noto Sans KR-Light";
  font-size: 14px;
  color: #ffd900;
}
.videoPreview .line {
  width: 100%;
  height: 1px;
  background-color: #8f8f8f;
  margin: 24px 0;
}
.videoPreview .notes {
  background-color: transparent;
  padding: 0 16px;
  margin-bottom: 48px;
}
.videoPreview .notes h3 {
  color: #fff;
  font-size: 15px;
  margin-bottom: 8px;
}
.videoPreview .notes h4 {
  color: #d8d8d8;
  font-size: 13px;
  line-height: 150%;
}

/* 서비스 정보 - 두번째 섹션 */
.provisionalServices {
  background-color: #fff;
  color: #212121;
  padding: 72px 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.provisionalServicesTitle {
  padding: 0 40px;
}
.provisionalServices h3 {
  color: var(--default-color);
  font-size: 15px;
  font-family: "Noto Sans KR-Bold";
}
.provisionalServices h2 {
  font-family: "Noto Sans KR-Bold";
  font-size: 26px;
  margin: 8px 0 16px 0;
}
.provisionalServices h4 {
  font-family: "Noto Sans KR-Regular";
  font-size: 15px;
  color: #777;
  line-height: 130%;
}
.provisionalServicesImgRight {
  display: flex;
  justify-content: end;
  margin-top: 32px;
}
.provisionalServicesImgLeft {
  display: flex;
  justify-content: baseline;
  margin-top: 40px;
}
.provisionalServicesImgLeft img {
  width: 80%;
  height: auto;
}
.provisionalServicesImgRight img {
  width: 60%;
  height: auto;
}

/* 자주묻는 질문 */

.faqSection .faqSectionButtons {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  gap: 10px;
  padding: 0 16px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  margin: 40px 0;
}

.faqSection .faqSectionButtons::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
.faqSection .faqSectionButtons button {
  padding: 20px 30px;
  border-radius: 10px;
  font-family: "Noto Sans KR-Mideum";
  font-weight: 700;
  font-size: 14px;
  color: #86868b;
  border: 1px solid #86868b;
}
.faqSection .faqSectionButtons .buttonActive {
  background-color: #0e2b40;
  color: var(--default-color);
  border: 1px solid var(--default-color);
}

.faqSection .faqSectionButtons .buttonActive img {
  filter: brightness(0) saturate(100%) invert(48%) sepia(95%) saturate(1944%)
    hue-rotate(190deg) brightness(101%) contrast(101%);
}

.faqSectionContent {
  margin: 0 16px;
}
.faqSectionContent > div {
  border-bottom: 1px solid#8F8F8F;
}
.faqSectionContent .faqSectionContentQuestion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin: 40px 0;
}
.faqSectionContent h3 {
  font-family: "Noto Sans KR-Bold";
  font-size: 20px;
  color: #f5f5f7;
  line-height: 140%;
}
.faqSectionContentAnswer {
  display: flex;
  /* justify-content: space-between; */
  padding: 0px 8px;
  gap: 24px;
  margin-top: 40px;
  line-height: 130%;
  font-family: "Noto Sans KR-Mideum";
  font-size: 16px;
  margin-bottom: 40px;
}
.faqSectionContentAnswer span {
  color: var(--default-color);
  font-family: "Noto Sans KR-Bold";
  font-size: 22px;
}

.makeInfo {
  padding: 80px 16px 48px 16px;
}
.makeInfoTitle h6 {
  font-family: "Noto Sans KR-Bold";
  font-size: 20px;
  color: #f5f5f7;
  margin-bottom: 24px;
  text-align: center;
}

.newEventUsb {
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-family: "Noto Sans KR-Bold";
  padding: 72px 16px 80px;
  background-color: #262626;
}
.newEventUsb h3 {
  font-size: 18px;
  text-align: center;
}
.newEventUsb p {
  font-size: 22px;
  line-height: 140%;
  color: #86868b;
}
.newEventUsb p span {
  color: #f5f5f7;
}

.newEventUsb img {
  width: 80%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.videoOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.videoContainer {
  /* position: relative; */
  width: 90%;
  /* max-width: 800px; */
  background-color: #262626;
  padding-top: 32px;
  border-radius: 10px;
  padding-bottom: 24px;
}

.videoContainer h2 {
  text-align: center;
  font-family: "Noto Sans KR-Bold";
  font-size: 20px;
  color: #f5f5f7;
  margin-bottom: 24px;
}
.videoContainer h3 {
  text-align: center;
  font-family: "Noto Sans KR-Regular";
  font-size: 15px;
  color: #f5f5f7;
  margin-bottom: 8px;
}

.overlayVideo {
  display: block;
  margin: 0 auto;
}

.closeButton {
  /* position: absolute;
  bottom: -40px;
  right: 40px; */
  /* background-color: white; */
  border: none;
  display: block;
  margin: 0 auto;
}

.closeButton img {
  width: 28px;
  height: 28px;
}
