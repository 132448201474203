/* preview canvas */
.canvas {
  width: 100%;
  height: auto;
  background-color: #ffffff;
}

/* preview div */
.imgEditPreview {
  position: relative;
  background-color: #ffffff;
  width: 100%;
  height: 475px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
}

@media (max-width: 1200px) {
  /* preview div */
  .imgEditPreview {
    background-color: #ffffff;
    width: 900px;
    height: 524px;
  }
}

@media (max-width: 1000px) {
  /* preview div */
  .imgEditPreview {
    width: 700px;
    height: 410px;
  }
}

@media (max-width: 768px) {
  /* preview div */
  .imgEditPreview {
    width: 100%;
    height: 200px;
    padding: 0px;
  }
}
