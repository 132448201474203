.overlay {
  display: block;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
}

.container {
  width: 100%;
  max-width: 500px;
  padding: 32px 24px 64px 24px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 10px 10px 0px 0px;
  background: var(--BG-Dark-Grey, #2c2c2c);

  box-sizing: border-box;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}

.container .titleWrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.title {
  color: #fff;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;

  font-weight: 500;
  line-height: 160%;
}

.desc {
  color: #d8d8d8;

  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}

.buttonWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.buttonWrap button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  height: 48px;
  padding: 10px 8px 10px 10px;
  border-radius: 10px;

  background-color: var(--default-color);
  color: #fff;

  font-family: "Noto Sans CJK KR";
  font-size: 14px;

  font-weight: 700;
  line-height: 140%; /* 19.6px */
}

.buttonWrap button:last-child {
  background: #214369;
  color: var(--default-color);
}
