.container {
  display: inline-block;
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
}

.container header {
  border-bottom: 1px solid #d1d5db;
  padding: 16px;

  display: flex;
  justify-content: flex-end;
}

.container header span {
  font-size: 14px;
  color: #6b7280;
}

.noDataText {
  font-size: 14px;
  color: #4b5563;

  padding: 24px;
  text-align: center;
}
