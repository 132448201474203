.cancelButton span {
  font-family: Pretendard, sans-serif;
  font-size: 11px;
  line-height: 13px;
  font-weight: 700;
  word-break: keep-all;
  color: #d03362;
  text-decoration: underline #d03362;
  text-underline-offset: 4px;
  display: inline-block;
  transition: transform 0.2s;
}
.cancelButton:hover {
  transform: translateY(-2px);
}

.orderTr {
  cursor: pointer;
}
.orderTr td {
  padding: 12px 0px;
  text-align: center;
}

.exSmallTh {
  width: 30px !important;
  text-align: center !important;
}

.smallTh {
  width: 50px !important;
  text-align: center !important;
}

.middleTh {
  width: 80px !important;
  text-align: center !important;
}

.largeTh {
  width: 200px !important;
  text-align: center !important;
}
.exLargeTh {
  width: 400px !important;
  text-align: center !important;
}
.funcWrap {
  display: flex;
  margin-top: 16px;
  align-items: center;
  gap: 16px;
}

.deliBtn {
  color: #4d5159;
  border: 1px solid #d2d5dc;
  background-color: #fff;

  padding: 0 16px;
  line-height: 28px;
}

.funcWrap p {
  color: #4d5159;
  font-size: 12px;
}
