.mainWarp {
  display: flex;
  gap: 32px;

  min-height: 100vh;

  margin-top: 24px;
}

.previewSection {
  background-color: floralwhite;
  flex: 1;
}
.previewSection h4 {
  font-family: "Noto Sans KR-Medium";
  font-size: 16px;
}
.previewSection p {
  font-family: "Noto Sans KR-Regular";
  font-size: 14px;
  color: #666;
  margin: 8px 0;
}
.previewSection img {
  width: 100%;
  padding-bottom: 24px;
}

.inputSection {
  flex: 1;
}
.inputSection > div {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.inputSectionHeader {
  border-bottom: 1px solid #edf2f7;
  padding: 20px 16px;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.inputSectionHeader h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5rem;
  color: #1a202c;
}

.inputSectionHeader button {
  display: inline-flex;
  align-items: center;
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  outline: none;
  font-weight: 500;
  padding: 8px 16px;
  background-color: white;
  color: #4a4a4a;
  transition: background-color 0.2s;
}

.inputSectionHeader button:hover {
  background-color: #f5f5f5;
}

.inputSectionHeader button svg {
  height: 20px;
  width: 20px;
  color: #4a4a4a;
  margin-left: -4px;
  margin-right: 8px;
}

.inputSectionBody {
  padding: 24px 16px;
}

.inputSectionBody textarea {
  border: 1px solid #d1d1d1;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  border-radius: 6px;
  resize: vertical;
  padding: 8px;
  height: 36px;
}

.inputSectionBody textarea:focus {
  border: 2px solid #667eea;
}

.inputSectionBody .inputLabelWrap {
  margin-top: 24px;
}
.inputSectionBody .labelWrap {
  margin-bottom: 4px;
}
.inputSectionBody .labelWrap label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
}

.inputSectionBody .inputWrap {
  display: flex;
  flex-direction: column;
}

.inputSectionBody p {
  margin-top: 4px;
  font-size: 14px;
  color: var(--color-brand);
}
.imageUploadWrap {
  padding: 24px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imageUploadWrap p {
  margin-top: 4px;
  font-size: 14px;
  color: var(--color-brand);
}
.imageUploadWrap button {
  background-color: var(--color-brand);

  color: #fff;
  font-weight: bold;

  width: 100px;
  height: 40px;

  border-radius: 8px;
}

.imagePreviewWrap {
  padding: 0px 16px;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 16px;
  row-gap: 16px;
}
.imagePreviewWrap img {
  border: 1px solid #d1d1d1;
  border-radius: 4px;

  width: 100px;
  height: 100px;
  object-fit: contain;
}

.bottmWrap {
  padding: 24px 16px;
}

.nextButton {
  background-color: var(--color-brand);

  color: #fff;
  font-weight: bold;

  width: 100%;
  height: 44px;

  border-radius: 8px;
  font-size: 16px;
}

@media (max-width: 1024px) {
  .mainWarp {
    flex-direction: column;
  }
}
