.text-preview {
  width: 100%;
  position: relative;
  height: 173px;
}

.text-preview img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.text-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
}
