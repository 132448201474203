.iconWrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.userIcon {
  margin-bottom: 24px;
}
.title {
  font-family: "Noto Sans KR-Medium";
  font-size: 18px;
  color: #fff;
  margin-bottom: 16px;
}
.subtitle {
  font-family: "Noto Sans KR-Medium";
  font-size: 14px;
  color: #fff;
  margin-top: 32px;
  margin-bottom: 16px;
}
.desc {
  font-family: "Noto Sans KR-Light";
  font-size: 14px;
  color: var(--light-font-color);
  line-height: 140%;
}

.inputWraps {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
}

.inputWraps .inputWrap label {
  display: inline-block;
  margin-bottom: 8px;
  color: #fff;
  font-family: "Noto Sans KR-Regular";
  font-size: 14px;
}
.inputWraps .inputWrap input,
.inputWraps .inputWrap select {
  background-color: var(--dark-light-bg-color);
  border: 1px solid #777777;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  border-radius: 6px;
  resize: vertical;
  padding: 12px 10px;
  box-sizing: border-box;
  color: #fff;
}

.inputWraps .inputWrap input:focus,
.inputWraps .inputWrap select:focus {
  border: 2px solid #667eea;
}

/* .inputWraps .inputWrap input:active,
.inputWraps .inputWrap select:active {
  border: 2px solid #667eea;
} */

.buttonWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 40px;
  margin-bottom: 32px;
}

.buttonWrap button {
  background-color: var(--button-main-color);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  width: 100%;
  /* padding: 8px 16px; */
  border-radius: 4px;
  min-width: fit-content;
}

.buttonWrap button.cancel {
  background-color: var(--color-grey);
  color: var(--color-text);
}

.checkButtonWrap {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  padding-left: 8px;
  margin-bottom: 12px;
  cursor: pointer;
}
.checkButtonWrap:hover .desc {
  color: #339dff;
}
.checkButton {
  width: 20px;
  height: 20px;
  border: solid 1px #8f8f8f;
  border-radius: 50%;
}

.checkButton.active {
  background-image: url("@assets/images/service/check-box-blue.svg");
  border: none;
  background-size: contain;
}
