/* Navbar.module.css */

.header {
  width: 100%;
  height: 120px;
  padding: 0px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  position: fixed;
  top: 0;
  z-index: 999;
  box-sizing: border-box;
  background-color: transparent; /* Add background color for visibility */
}

.header nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: auto;
}

.header nav img {
  width: 207px;
  height: 80px;
  flex-shrink: 0;
}

.header ul {
  display: flex;
  gap: 40px;
}

.header a {
  color: var(--icon-color, #fff);
  font-family: "Noto Sans KR";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.header ul a:hover {
  color: var(--default-color) !important;
}

/* Hamburger menu button */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger div {
  width: 24px;
  height: 2px;
  background-color: var(--icon-color, #fff);
  margin: 3px 0;
}

/* Responsive styling */
@media (max-width: 768px) {
  .header {
    padding: 0 20px;
    height: 70px;
    background-color: #000;
  }

  .header ul {
    display: none;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    position: fixed;
    top: 70px;
    bottom: 0px;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 180px 20px;
    margin: auto;
  }

  .header ul.active {
    display: flex;
  }

  .header ul a:first-child {
    flex: 1;
  }

  .header ul .logoImage {
    width: 200px;
    object-fit: contain;
  }
  .header ul li {
    width: 100%;
    text-align: center;

    display: flex;
    align-items: center;
    flex: 1;
  }
  .header ul a {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
  }

  .hamburger {
    display: flex;
  }

  .header nav img {
    display: none;
  }
}
