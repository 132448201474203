.tag {
  height: 18px;
  padding: 0px 6px;
  border-radius: 6px;
  font-size: 12px;

  font-weight: 500;
  word-break: keep-all;

  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.tag.blackTag {
  background-color: #262626;
  color: #fff;
}

.tag.greenTag {
  background-color: #e4faf3;
  color: #16b079;
}
.tag.blueTag {
  background-color: #e3f5ff;
  color: #219dd1;
}
.tag.indigoTag {
  background-color: #efe3ff;
  color: var(--color-brand);
}
.tag.redTag {
  background-color: #fbeef2;
  color: #d03362;
}
.tag.yellowTag {
  background-color: #f9f3e5;
  color: #d19b0f;
}

.tag.greyTag {
  background-color: #eff0f1;
  color: #676a6c;
}
