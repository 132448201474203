.exSmallTh {
  width: 30px !important;
}

.smallTh {
  width: 60px !important;
}

.middleTh {
  width: 80px !important;
}

.defaultText {
  /* 길이가 길면 ...로 표시 */
  white-space: wrap !important;
}

.textarea {
  background-color: transparent;

  border: 1px solid var(--default-color);
  border-radius: 8px;

  padding: 10px;

  width: 80%;
  box-sizing: border-box;
  color: #4b5563;
  transition: border-color 0.4s;
}

.cancelButton {
  background-color: #fbeef2;
  color: #d03362;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  transition: all 0.1s;

  margin-left: 8px;
}
.cancelButton:hover {
  transform: scale(1.1);
}

.editButton {
  background-color: #f5eefb;

  color: var(--color-brand);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  transition: all 0.1s;
}
