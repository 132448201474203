.hamburger {
  display: none;
  z-index: 1000;
}

@media (max-width: 768px) {
  .hamburger {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    display: flex;
    gap: 4px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* width: 40px; */
    /* height: 30px; */
    position: absolute;
    top: 10px;
    left: 5px;
  }

  .hamburgerLine {
    width: 24px;
    height: 2px;
    background-color: #222222;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
  }
  /* isOpen 상태일 때 각 라인의 변화 */
  .open .line1 {
    transform: rotate(45deg) translate(4.5px, 4.5px);
    background-color: #ffffff;
  }

  .open .line2 {
    opacity: 0;
  }

  .open .line3 {
    transform: rotate(-45deg) translate(4.5px, -4.5px);
    background-color: #ffffff;
  }
}
