.section {
  width: 100%;

  font-family: "Pretendard Variable";
  font-style: normal;
}

.section > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section h1 {
  font-family: "Noto Sans KR-Bold";
  font-size: 24px;

  line-height: 1.75;
  color: #2d3748;
}

.section p {
  margin-top: 4px;
  font-size: 14px;
  color: #6b7280;
}

@media (max-width: 768px) {
  .section {
    padding-top: 24px;
  }
  .section h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
