.article {
  background-color: #ffffff;

  border-radius: 8px;

  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  width: 100%;
  height: fit-content;

  padding: 16px;

  box-sizing: border-box;
}

.headerSection {
  display: flex;
  justify-content: space-between;

  margin-bottom: 24px;
  font-weight: 600;
}

.headerSection button {
  color: var(--color-brand);
  font-size: 16px;
}

.bodySection {
  height: 100%;
}
