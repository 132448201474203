.mainSection {
  max-width: var(--maxwidth);
  margin: auto;
  font-family: "Noto Sans KR";
  overflow-x: auto;
}

.sectionTitle {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  flex-shrink: 0;
  position: relative;
  margin-bottom: 56px;
}

.sectionTitle h2 {
  color: #ffffff;
  text-align: left;
  font-size: 40px;
  font-weight: 700;
  position: relative;
}

.mainSection .viewAll {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.mainSection .viewAll span {
  color: #ffffff;
  text-align: right;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  cursor: pointer;
}

.arrowChevronRight {
  flex-shrink: 0 !important;
  cursor: pointer;
}
.sectionContents {
  display: flex;
  gap: 40px;
}

/* 반응형 */
@media (max-width: 768px) {
  .sectionTitle h2 {
    font-size: 24px;
  }
  .mainSection .viewAll span {
    font-size: 15px;
    font-weight: 400;
  }
  .arrowChevronRight {
    width: 18px;
  }
  .sectionTitle {
    padding: 0 24px;
    margin-bottom: 24px;
  }
  .sectionContents {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    gap: 50px;
  }
}
