.wrap {
  display: flex;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 10px;
  background: #fff;

  height: 120px;
}

.wrap h6 {
  color: #383838;
  font-family: "Noto Sans KR";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 106.667% */
}

.wrap h4 {
  color: #000;

  font-family: "Noto Sans KR";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 72.727% */
  margin-top: 16px;
  margin-bottom: 0;
}

.iconWrap {
  display: flex;
  width: 56px;
  height: 56px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .wrap {
    height: 90px;
  }
  .wrap h6 {
    font-size: 12px;
  }

  .wrap h4 {
    font-size: 14px;
  }

  .iconWrap {
    width: 28px;
    height: 28px;
  }
}
