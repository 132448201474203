.mainPage,
.mainPage * {
  box-sizing: border-box;
}

.mainPage {
  margin: auto;
  background: #0a0a0a;
  position: relative;
  color: #ffffff;
}

.mainPage main {
  /* max-width: var(--maxwidth);
  margin: auto; */
}

.mainPage .contents {
  position: relative;
}

.mainPage .mainImage {
  /* width: 100%;
  height: 100vh; */
  object-fit: cover;
}

.mainPage .mainImage img {
  height: 100%;
  min-width: 1920px;
  width: 100%;
}

.mainPage .scrollDownWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.mainPage .scrollDown {
  color: #ffffff;
  text-align: center;
  font-family: "NotoSansKr-Medium", sans-serif;
  font-size: 18px;
  font-weight: 500;
  position: relative;
}

.mainPage .arrowChevronDown {
  flex-shrink: 0 !important;
  width: 50px !important;
  height: 50px !important;
}

.mainPage .slogan {
  width: 100%;
  max-width: var(--maxwidth);
  margin: auto;
  height: 790px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainPage .slogan p {
  color: #ffffff;
  text-align: center;
  font-family: "NotoSansKr-Bold", sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 1.5;
}

.mainPage .projectWrap {
  padding: 160px 0;
}
.mainPage .contactWrap {
  background-color: #ffffff;
}

.contactWrap .contactInfoBoxs {
  display: flex;
}

.contactInfoBox {
  flex: 1;
  height: 360px;
  background-size: cover;

  position: relative;
  isolation: isolate;
}

.contactInfoBox::after {
  content: "";
  position: absolute;
  background: black;
  z-index: -1;
  inset: 0;
  opacity: 0.4;
}

.contactInfoBox .infoWrap {
  z-index: 999;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
}

.contactInfoBox .infoWrap h6 {
  margin-bottom: 24px;
  font-size: 18px;
  font-family: "NotoSansKr-Bold", sans-serif;
  font-weight: 700;
}

.contactInfoBox .infoWrap p {
  font-size: 16px;
  font-family: "NotoSansKr-Medium", sans-serif;
  font-weight: 500;
  line-height: 2;
}

.contactInfoBox:first-child {
  background-image: url("../../assets/images/home/contact_1.jpg");
}

.contactInfoBox:nth-child(2) {
  background-image: url("../../assets/images/home/contact_2.jpg");
}

.contactInfoBox:last-child {
  background-image: url("../../assets/images/home/contact_3.jpg");
}

.contactWrap .logoBoxs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 120px;
}

.contactWrap .logoBoxs img {
  width: 250px;
  object-fit: contain;
}
.contactWrap .contactBox {
  height: fit-content;
  background-image: url("../../assets/images/home/contact_bg.jpg");
  filter: grayscale(100%);
  background-size: cover;
  background-position-y: center;
  position: relative;
  isolation: isolate;

  padding: 80px 0px;
}

.dividerWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 48px 0px;
}

.divider {
  width: 24px;
  height: 8px;

  background: #fff;
}

.contactWrap .contactBox::after {
  content: "";
  position: absolute;
  background: black;
  z-index: -1;
  inset: 0;
  opacity: 0.8;
}

.contactWrap .contactBox h6 {
  font-size: 36px;
  font-family: "NotoSansKr-Bold", sans-serif;
  font-weight: 700;
  text-align: center;
  line-height: 1.5;
}

.contactInputs {
  display: flex;
  flex-direction: column;
  gap: 30px;
  box-sizing: border-box;
  max-width: var(--maxwidth);
  margin: auto;

  padding: 16px;
}
.contactInputs > div {
  display: flex;
  gap: 30px;
}
.contactInput {
  width: 100%;
}
.contactInput h3 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
}
.contactInput input {
  background-color: transparent;
  border: 1px solid #fff;
  font-size: 16px;
  width: 100%;
  height: 50px;
  padding: 0 24px;
  box-sizing: border-box;
  color: #fff;
}
.contactInput input:focus {
  border-color: var(--default-color);
}

.contactInput input::placeholder,
.contactInput textarea::placeholder {
  color: #fff;
}
.contactInput textarea {
  background-color: transparent;
  border: 1px solid #fff;
  font-size: 18px;
  width: 100%;
  height: 200px;
  padding: 16px 24px;
  box-sizing: border-box;
  color: #fff;
}

.contactInputs button {
  color: #fff;
  width: 160px;
  height: 50px;
  border: 1px solid #fff;
  background-color: #222222;
  font-size: 16px;
  margin: auto;
  transition: var(--dissolve);
}
.contactInputs button:hover {
  color: var(--default-color);
  border-color: var(--default-color);
  /* background-color: var(--hoverBGColor); */
}
@media (max-width: 768px) {
  .mainPage .projectWrap {
    padding: 60px 0px;
  }

  .mainPage .contactWrap .contactInfoBoxs {
    flex-direction: column;
  }
  .mainPage .scrollDownWrapper {
    left: 0;
    right: 0;
    bottom: 50px;
  }
  .mainPage .scrollDown {
    font-size: 15px;
    font-weight: 500;
  }
  .mainPage .arrowChevronDown {
    width: 40px !important;
    height: 40px !important;
  }
  .mainPage .slogan {
    height: 500px;
  }
  .mainPage .slogan p {
    font-size: 24px;
  }
  .projectWrap .projects .infoWrap h3 {
    font-size: 20px;
  }
  .mainPage .viewMoreWrap {
    margin-top: 0px;
  }
  .mainPage .viewMore span {
    font-size: 15px;
    font-weight: 500;
    height: 48px;
    padding: 0 30px;
  }
}
