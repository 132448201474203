.serviceTemplateCheck {
  color: #fff;
  background-color: #1a1a1a;
  padding: 48px 0 8px 0;
}

.serviceTemplateCheck > main {
  /* margin-top: 36px; */
}

.templatesSection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-flow: column;
  grid-auto-columns: minmax(300px, 1fr);
  overflow-x: auto;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
  padding: 0px 16px;
  box-sizing: border-box;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.templatesSection::-webkit-scrollbar {
  display: none; /* WebKit */
}
.serviceTemplateCheck main h2 {
  color: #f5f5f7;
  text-align: center;
  font-family: "Noto Sans KR-Medium";
  font-size: 18px;
  line-height: 140%;
}

.typeNav {
  font-family: "Noto Sans KR-Medium";
}

.typeNav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 10px;
}

.typeNav li {
  width: 70px;
  max-width: 100px;
  text-align: center;
  font-family: "Noto Sans KR-Regular";
  font-size: 15px;
  padding: 14px 20px;
  border-radius: 50px;
  border: 1px solid #8f8f8f;
}

.typeNav li.active {
  border: 1px solid #f5f5f7;
  background: #1e5d98;
}
