.exSmallTh {
  width: 30px !important;
}

.smallTh {
  width: 50px !important;
}

.middleTh {
  width: 120px !important;
}

.largeTh {
  width: 160px !important;
}

.contentTd {
  white-space: wrap;
}
