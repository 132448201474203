.dateWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quickButtons {
  display: flex;
  align-items: center;
  gap: 12px;
}
.quickButtons::after {
  content: "";
  position: relative;
  display: block;
  border-right: 1px solid #a2a2a2;
  width: 1px;
  height: 26px;
  margin-left: 8px;
}

.quickButtons button,
.checkButton {
  display: inline-flex;

  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;

  height: 40px;
  padding: 0px 16px;

  -webkit-user-select: none;
  user-select: none;
  transition: transform 0.2s;
  white-space: nowrap;

  color: #a7a7a7;
  font-family: Pretendard, sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  word-break: keep-all;

  border: 1px solid #eaeaea;
  background-color: #fff;
}

.quickButtons button.active {
  font-weight: 700;
  color: #000;
  border: 1.5px solid #000000;
}

.quickButtons button:hover,
.checkButton:hover,
.refreshButton:hover {
  transform: translateY(-2px);
}

.rightWrap {
  display: flex;
  gap: 16px;
}

.rightWrap button {
  font-weight: 700;
  color: #000;
  border: 1.5px solid #000000;
}

.refreshButton {
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  transition: all 0.2s ease-in;
  color: #767676;
  cursor: pointer;
  background-color: #fff;
  font-size: 18px;
}

.dateWrap .leftWrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* 검색 조건 선택 박스 */
.searchBox {
  display: flex;
  gap: 24px;
  align-items: flex-end;

  padding: 16px;

  margin-top: 24px;

  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;

  background-color: #fff;

  border-radius: 8px;
}
.searchBox .searchFilters {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.searchBox .filterItemWraps {
  display: flex;
  gap: 16px;
}

.searchBox .filterItemWraps .statusFilterNav {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  margin: 16px 0 4px 0;
}

.searchBox .filterItemWraps .statusFilterNav button {
  display: inline-flex;
  flex-shrink: 0;
  gap: 4px;
  align-items: center;
  padding-bottom: 6px;

  cursor: pointer;
  border: 0;
  border-radius: unset;

  color: #bfbfbf;
}

.searchBox .filterItemWraps .statusFilterNav button.active {
  box-shadow: inset 0 -2px #000;
  color: #000 !important;
}

.searchBox .filterItemWraps .statusFilterNav .statusText {
  font-weight: 700;
  font-size: 14px;

  font-family: Pretendard, sans-serif;
}

.statusTag {
  height: 18px;
  padding: 0px 6px;
  border-radius: 6px;
  font-size: 12px;

  font-weight: 500;
  word-break: keep-all;

  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.blackTag {
  background-color: #262626;
  color: #fff;
}

.greenTag {
  background-color: #e4faf3;
  color: #16b079;
}
.blueTag {
  background-color: #e3f5ff;
  color: #219dd1;
}
.redTag {
  background-color: #fbeef2;
  color: #d03362;
}
.yellowTag {
  background-color: #f9f3e5;
  color: #d19b0f;
}

.indigoTag {
  background-color: #efe3ff;
  color: var(--color-brand);
}
.greyTag {
  background-color: #eff0f1;
  color: #676a6c;
}

.searchBox .filterItem.date-filter {
  max-width: 920px;
}

.searchBox .filterItem {
  flex: 1;
  gap: 4px;
  display: flex;
  align-items: center;
}
.searchBox .filterItem label {
  font-weight: bold;
  background-color: #f7f7f7;
  padding: 9.5px 12px;
  flex-shrink: 0;

  width: 60px;

  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}
.searchBox .filterItem input,
.searchBox .filterItem .payMethodSelect {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  width: 100%;
}

.searchBox .quick-filters {
  display: flex;
  gap: 6px;
}
.searchBtns {
  display: flex;
  gap: 8px;
}
.searchBtns button {
  background-color: var(--color-brand);
  color: #fff;

  width: 60px;
  height: 36px;

  border-radius: 12px;

  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.searchBtns button.reset {
  background: transparent;
  border: 1px solid #2b3240;

  color: #2b3240;
}
.searchBtns button.excel {
  background-color: #668666;
}

@media (max-width: 1400px) {
  /* 레이아웃 최적화 */
  .searchBox {
    padding: 8px;
    gap: 8px;
    flex-direction: column;
    overflow: hidden;
  }

  .searchBox .searchFilters {
    flex-direction: column;
    width: 100%;
  }
  .searchBox .filterItemWraps {
    flex-direction: column;
    gap: 12px;
  }
  .searchBox .filterItemWraps .statusFilterNav {
    flex-wrap: wrap;
  }

  .filterItem.date-filter {
    flex-wrap: wrap;
    margin-bottom: 16px;
  }

  .searchBox .filterItem .date-filter label {
    margin-bottom: 8px;
  }

  .searchBox .filterItem input,
  .searchBox .filterItem select {
    max-width: 100%;
    box-sizing: border-box;
  }

  .searchBtns button {
    width: 100px;
    height: 40px;
    /* 두 버튼이 한 줄에 나올 수 있도록 조절. 필요에 따라 조절하세요. */
  }

  /* 상태 필터 내부 버튼 스타일 조절 */
  .searchBox .filterItemWraps .statusFilterNav button {
    flex: 1; /* 버튼 간의 너비를 동일하게 */
    margin: 4px 2px; /* 일정한 여백 부여 */
    flex-basis: fit-content;
  }

  .searchBtns .excel {
    display: none;
  }

  .searchBox .quick-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  /* 텍스트 크기 최적화 */
  .searchBox .filterItem label {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .dateWrap .leftWrap {
    gap: 8px;
    flex-wrap: wrap;
  }
}
