.tableWrap {
  width: 100%;
  background-color: #fff;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  overflow-x: auto; /* 가로 스크롤을 활성화합니다 */
}

.table {
  width: 100%;
  table-layout: fixed; /* 고정된 테이블 레이아웃 */
}

.table th,
.table td {
  padding: 8px 16px;
  text-align: left;
  white-space: nowrap; /* 텍스트 줄바꿈을 방지하여 셀 너비를 유지합니다 */
}

.table th {
  width: 150px; /* 각 셀의 고정 너비를 지정합니다 */
}
