.input-hidden {
  display: none;
}

.images-upload_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 13rem;
  border: 1px dashed #9d9d9d;
  border-radius: 1rem;
  transition: background-color 0.3s;
}

.images-upload_box:hover {
  background-color: #3d3ace;
}

.images-upload_box img {
  width: 36px;
  height: 36px;
}

.images-upload_box h4 {
  color: white;
  font-family: Noto Sans KR-Medium;
  font-size: 16px;
  /* font-weight: bold;
  font-size: 1.125rem; */
}

.images-upload_btn {
  width: 108px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;

  border: 1px solid var(--button-main-color);
  border-radius: 4px;
  color: var(--button-main-color);
}

.images-upload_btn-icon {
  font-size: 1.25rem;
}

.images-upload_btn span {
  font-weight: 700;
  font-size: 14px;
}

/* preview section */
.service-image-preview_section {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 370px);
}

.service-image-preview_section .section-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service-image-preview_section .section-header span {
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.service-image-preview_section .section-header button {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 1rem;
}

.service-image-preview_section .section-header .delete_btn {
  color: red;
  margin-right: 0;
}

.service-image-preview_section .section-header .choose_btn {
  color: var(--default-color);
  margin-right: 0;
}

.service-image-preview_section .main-content {
  box-sizing: border-box;
  border: 1px dashed;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-top: 1rem;
  flex: 1;
  overflow: auto;
}

.service-image-preview_section .upload-box {
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 1.75rem;
}

.service-image-preview_section .image-grid {
  box-sizing: border-box;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
}

.service-image-box {
  position: relative;
}

.service-image-box img {
  width: 100%;
  height: 9rem;
  object-fit: contain;
}

.service-image-box .image-box-checked {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  margin: auto;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: var(--default-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-image-box .image-box-icon {
  color: white;
}

.service-image-box .edit-overlay {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.service-image-box .edit-icon {
  color: white;
  font-size: 1.5rem;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.image-change_section {
  position: relative;
}
.image-change_section .image-preview_wrap {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
}
.image-change_section .image-preview_wrap img {
  object-fit: contain;
  width: 100%;
  height: 208px;
}
.image-change_section .image-preview_wrap canvas {
  width: 100% !important;
  height: 100% !important;
  /* object-fit: contain; */
}

.image-change_section {
  position: relative;
  animation: fadeInRight 0.8s;
}

.image-change_section .edit-fuction_btns {
  display: flex;

  justify-content: space-between;
  align-items: center;

  padding: 0px 22px;
  margin-top: 40px;
  margin-bottom: 24px;
}
.image-change_section .bottom-button_wraps {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: center;
  margin-top: 64px;
}

.image-change_section .bottom-button_wraps button {
  background: #214369;
  border-radius: 4px;

  width: 100%;
  height: 44px;
  color: var(--default-color);
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
}

.image-change_section .bottom-button_wraps button.apply {
  background: var(--Button-Default-BG, #339dff);
  color: var(--Button-Text-Default, var(--icon-color, #fff));
  text-align: center;
}

@media (max-width: 768px) {
  .image-change_section .image-preview_wrap {
    height: 234px;
  }

  .image-change_section .edit-fuction_btns {
    margin-top: 16px;
    padding: 0px 4px;
  }
  .image-change_section .edit-fuction_btns.rotate-btns {
    margin-top: 32px;
  }

  .image-change_section .bottom-button_wraps {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #1e1e1e;
    z-index: 999;
    height: 100px;
    padding: 16px;
    box-sizing: border-box;

    align-items: start;
  }
}
