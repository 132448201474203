.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.imgContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 40px;
}

.imgContainer img {
  width: 28px;
}

.imgContainer h1 {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
}

.form {
  width: 100%;
  max-width: 400px;
}

.form nav {
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 8px;
  margin-bottom: 24px;
}

.form nav span {
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.form nav span:first-child {
  padding-bottom: 4px;
  margin-right: 24px;
}

.idPwContainer {
  margin-bottom: 16px;
}
.idContainer {
  margin-bottom: 8px;
}
.inputWrap {
  position: relative;
}

.inputWrap input {
  width: 100%;
  padding-top: 8px;
}
.inputWrap label .icon,
.inputWrap .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
  margin: auto;

  font-size: 20px;
  line-height: 28px;
  color: var(--color-brand);
}

.inputWrap button.icon {
  right: 0px;
}

/* 로그인 상태 유지 & 비밀번호 찾기 container */
.funcContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.funcContainer .checkboxWrap {
  display: flex;
  gap: 8px;
}

.funcContainer .checkboxWrap label,
.funcContainer h4 {
  font-size: 14px;
  line-height: 20px;

  cursor: pointer;
}

.funcContainer h4 {
  color: var(--color-brand);
}

/* 로그인 & 회원가입 container */
.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.buttonContainer button {
  width: 100%;
  padding: 8px;
  background-color: var(--color-brand);

  color: #fff;
  font-weight: 600;

  border-radius: 4px;
}

.buttonContainer button:last-child {
  background-color: transparent;
  border: 1px solid var(--color-brand);
  color: var(--color-brand);
  font-weight: 600;
}
