.name {
  font-family: "Noto Sans KR-Bold";
}
.mainWarp {
  display: flex;
  gap: 10%;
  margin-top: 40px;
  justify-content: center;
}

.infoWrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 500px;
  font-family: "Noto Sans KR-Regular";
  color: #666666;
}
.infoWrap div {
  display: flex;
  gap: 16px;
}
.infoWrap span {
  font-family: "Noto Sans KR-Regular";
  color: #666;
}
.infoWrap span:first-child {
  min-width: 72px;
  font-family: "Noto Sans KR-Medium";
  color: #222;
}

.thumImg {
  width: 400px;
  height: 400px;
}
.thumImg img {
  width: 100%;
  height: auto;
  min-width: 200px;
  object-fit: cover;
}

.makeButton {
  background-color: var(--color-brand);

  width: 100%;
  padding: 16px 20px;
  border-radius: 4px;

  text-align: center;

  margin-top: 24px;
  transition: background-color 0.15s ease-in-out;

  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}
/* 
.makeButton a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
} */

@media (max-width: 1024px) {
  .mainWarp {
    flex-direction: column-reverse;
    /* justify-content: center; */
    align-items: center;
    gap: 40px;
  }
}

@media (max-width: 480px) {
}
