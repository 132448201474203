.changeButton {
  margin-left: 8px;
  font-size: 16px;
}
.changeButton:hover {
  color: var(--color-brand);
}

.exSmallTh {
  width: 30px !important;
}

.smallTh {
  width: 50px !important;
}

.middleTh {
  width: 120px !important;
}

.largeTh {
  width: 160px !important;
}
