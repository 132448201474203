.cancelButton {
  background-color: #fbeef2;
  color: #d03362;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  transition: all 0.1s;
}
.cancelButton:hover {
  transform: scale(1.1);
}

.smallTh {
  width: 50px !important;
}

.detailsButton {
  background-color: #f5eefb;

  color: var(--color-brand);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  transition: all 0.1s;
}
