.title {
  font-family: "Noto Sans KR-Bold";
  font-size: 24px;
  line-height: 1.75;
  color: #2d3748;
}

.inputWraps {
  height: 490px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 24px 0px;
}

.inputWraps .inputWrap label {
  display: inline-block;
  margin-bottom: 8px;
}
.inputWraps .inputWrap input,
.inputWraps .inputWrap select {
  border: 1px solid #d1d1d1;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  border-radius: 6px;
  resize: vertical;
  padding: 8px;
}

.inputWraps .inputWrap input:focus,
.inputWraps .inputWrap select:focus {
  border: 2px solid #667eea;
}

.buttonWrap {
  display: flex;
  align-items: center;

  gap: 16px;
}

.buttonWrap button {
  background-color: var(--color-brand);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  height: 36px;
  width: 100%;
  /* padding: 8px 16px; */
  border-radius: 4px;
  min-width: fit-content;
}

.buttonWrap button.cancel {
  background-color: var(--color-grey);
  color: var(--color-text);
}
