.title {
  font-family: "Noto Sans KR-Bold";
  font-size: 24px;
  line-height: 1.75;
  color: #2d3748;
}
.inputWrap label {
  display: inline-block;
  margin-bottom: 8px;
}
.inputWrap input,
.inputWrap select {
  border: 1px solid #d1d1d1;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  border-radius: 6px;
  resize: vertical;
  padding: 8px;
  box-sizing: border-box;
}

.inputWrap input:focus,
.inputWrap select:focus {
  border: 2px solid #667eea;
}

.radioWraps {
  display: flex;

  gap: 16px;
}

.buttonWrap {
  display: flex;
  align-items: center;

  gap: 16px;
}

.buttonWrap button {
  background-color: var(--color-brand);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  height: 36px;
  width: 100%;
  /* padding: 8px 16px; */
  border-radius: 4px;
  min-width: fit-content;
}

.buttonWrap button.cancel {
  background-color: var(--color-grey);
  color: var(--color-text);
}

.editButton {
  width: 30px;
  cursor: pointer;
  color: var(--color-brand);
  font-size: 12px;
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 4px;
  padding: 4px;
}

.exSmallTh {
  width: 30px !important;
}

.smallTh {
  width: 50px !important;
}

.middleTh {
  width: 80px !important;
}
