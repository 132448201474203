.funcSection {
  margin-top: 16px;
}

.searchWrap {
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 12px;
}
.searchWrap form {
  flex: 1;
}

.mainWrap {
  margin-top: 24px;
}

.sumaryTable {
  width: 70%;
  table-layout: fixed;
}

.sumaryTable thead th {
  padding: 12px 16px;
  background-color: #f9fafb;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.sumaryTable tbody {
  background-color: #ffffff;
}

.sumaryTable tbody td {
  padding: 12px 16px;
}
