.bm-menu-wrap {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 99 !important;

  flex-shrink: 0;
}

.bm-menu {
  position: relative;
}

/* Position and sizing of burger button */
.bm-burger-button {
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  /* padding: 2.5rem 1.5rem 0; */
  font-size: 1.4rem;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #222222;
  background: var(--color-menu);
  padding: 0px 8px;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 56px;
  left: 0px;
}

/* custom css */
.sidebar {
  flex-shrink: 0;
  background: var(--color-menu);
  width: 250px;
  height: 100vh;
  position: sticky;
  top: 0;
}
.sidebar ul {
  background: var(--color-menu);
  padding: 0px 8px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sidebar .sidebar-group_wrap {
  border-bottom: 1px solid #eaeaea5e;
}

.sidebar .sidebar-group_wrap h6 {
  color: #a7a7a7;
  font-family: Pretendard, sans-serif;
  font-size: 12px;
}
.sidebar .sidebar-group_wrap span {
  font-family: "Noto Sans KR";
  font-size: 15px;
}

.sidebar-type-title h1 {
  height: 5rem;

  display: flex;
  align-items: center;

  color: var(--color-white);
  font-weight: 600;

  padding: 0px 16px;
}

.sidebar-list {
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 4px 8px;
  cursor: pointer;

  height: 40px;
  color: var(--color-white);
  font-size: 16px;

  margin-top: 4px;

  border-radius: 6px;
}

.sidebar-list:hover {
  background-color: #374152;
  font-weight: 600;
}

.sidebar-list.active {
  background: #111827;
}

.sidebar-list > div {
  display: flex;
  align-items: center;
  gap: 16px;

  font-family: "Pretendard Variable";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.sidebar-list.dropmenus {
  justify-content: space-between;
}
.sidebar-list.dropmenu {
  padding: 0px 32px;
}

.backdrop {
  display: none;
}

.logout_wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    /* display: none; */
    z-index: 999;
  }
  .sidebar.open {
    transform: translateX(0);
    padding-top: 40px;
  }
  .backdrop {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 990;
  }
}
