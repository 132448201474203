.tableHeader {
  border-bottom: 1px solid #dbdbdb;

  text-align: left;
  font-size: 14px;
}
.tableHeader th {
  padding: 12px 24px;
  background-color: #f9fafb;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
