.wrap {
  flex: 1;
}
.mainWrap {
  margin-top: 24px;
}

.flexWrap {
  display: flex;
  gap: 8px;

  margin-bottom: 24px;
}

.imageUploadWrap {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
}

.imageUploadWrap button {
  height: 100%;
  font-size: 16px;
  color: #bdbdbd;

  margin-top: 50px;
}

.inputWraps {
  display: flex;
  gap: 16px;

  margin-bottom: 16px;
}

.inputWrap {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-bottom: 16px;
}

.inputWrap label {
  font-size: 14px;
}
.inputWrap input,
.inputWrap select {
  width: 100%;
  height: 40px;
}

.headTilteSection {
  background-color: #fff;
  width: 100%;
  padding: 24px;
  margin-bottom: 16px;

  position: sticky;
  z-index: 999;

  box-sizing: border-box;
}

.headTilteSection > div {
  display: flex;
  align-items: center;
}

.headTilteSection > div h1 {
  flex: 1;

  font-family: "Pretendard Variable";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
}

.thumImg {
  width: 100%;
  height: 220px;
  object-fit: contain;
}
