.container {
  padding: 16px;
  padding-bottom: 8px;
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleWrap h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-brand);
  white-space: nowrap;
}

.titleWrap > div {
  margin-left: 8px;
  flex-shrink: 0;

  display: flex;
}
.titleWrap > div p {
  font-size: 14px;
  color: #b0b4bc;
}

.infoWrap {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoWrap > div p {
  font-size: 14px;
  color: #b0b4bc;
}

.assetsButton {
  color: var(--color-processing);
}
.editButton {
  color: var(--color-brand);
}

.deleteButton {
  color: var(--color-warning);
}

.makeButton {
  background-color: var(--color-brand);

  color: #fff;
  font-weight: bold;

  width: 80px;
  height: 30px;

  border-radius: 8px;
}
.makeButton.disable {
  background-color: #aaa7a7;

  color: #fff;
  font-weight: bold;

  width: 80px;
  height: 30px;

  border-radius: 8px;
}

.assetsButton:hover,
.makeButton:hover,
.deleteButton:hover,
.editButton:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.buttonWrap {
  display: flex;
  gap: 8px;
  align-items: center;
}
