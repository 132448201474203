.shopOrderCompletePage {
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;
  /* padding-bottom: 80px; */

  height: fit-content;
  min-height: 100vh;
  margin: auto;
  background: #f4f4f4;

  padding-top: 55px;
}

.titleWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  margin-bottom: 32px;
}
.titleWrap img {
  flex: 1;
  width: 40px;
  height: 40px;
}

.titleWrap h4 {
  flex: 1;
  color: #212121;
  text-align: center;

  /* Headline/noto 18 Bold */
  font-family: "Noto Sans CJK KR";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 25.2px */
}

.shopOrderCompletePage main {
  border-top: 20px solid #111;

  display: flex;

  flex-direction: column;
  align-items: flex-start;

  box-shadow: 0px 0px 17.2px 0px rgba(0, 0, 0, 0.15);

  margin: auto;
  background-color: #fff;

  width: 100%;
  max-width: 500px;
}

.vbankBox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0px 24px;
  padding-top: 24px;

  width: 100%;
  box-sizing: border-box;
}

.vbankBox h4 {
  color: #212121;
  font-family: "Noto Sans KR";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
}

.vbankBox button {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: #dee4e9;
}

.infoBox {
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-bottom: 1px dashed #d8d8d8;

  width: 100%;
}

.infoBox .infoWrap {
  display: flex;
  width: 100%;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: start;
  box-sizing: border-box;

  font-family: "Noto Sans KR";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.infoBox .infoWrap h6 {
  color: #8f8f8f;
  line-height: 1.4;
}
.infoBox .infoWrap p {
  color: #000;
  max-width: 150px;
  text-align: right;
  line-height: 1.4;
}

.infoBox .infoWrap h6.price {
  color: #212121;
}
.infoBox .infoWrap p.price {
  font-size: 16px;

  font-weight: 700;
}

.orderBottomBtn {
  display: flex;
  justify-content: center;
  padding: 16px 0 32px 0;
  /* height: 60px; */
  font-family: "Noto Sans KR-Medium";
  font-size: 16px;

  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;

  background-color: var(--default-color);
  color: #fff;
  max-width: 500px;
  margin: auto;
}

.retryButton {
  height: 60px;

  font-family: "Noto Sans KR-Medium";
  font-size: 16px;

  background-color: var(--default-color);
  color: #fff;
  max-width: 500px;
  margin: auto;

  width: 100%;
  border-radius: 10px;

  margin-top: 62px;
}
