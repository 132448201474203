.funcSection {
  margin-top: 16px;
}

.searchWrap {
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 12px;
}
.searchWrap form {
  flex: 1;
}
