.bottomOrderSection {
  width: 100%;
  max-width: 500px;
  padding: 24px 16px 48px 16px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #222;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0;
  transition: bottom 0.3s ease-in-out; /* 애니메이션 효과 */
  z-index: 999;

  gap: 24px;
  flex-direction: column;
}

.optionSelectBoxWrap {
  width: 100%;
  position: relative;
}

.infoWrap {
  width: 100%;

  display: flex;

  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;

  color: #fff;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
}

.priceInfoWrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.priceInfoWrap span {
  color: var(--default-color);
  font-weight: 700;
  line-height: 140%;
}

.priceInfoWrap p {
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
}

.buyBtn,
.makeBtn {
  width: 100%;

  display: flex;
  width: 180px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 6px;
  background: #107de2;
}

.buyBtn span,
.makeBtn span {
  color: #fff;
  text-align: center;

  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
}
.makeBtn {
  width: 100%;
}
.borderLine {
  width: 100%;
  border-top: 1px solid #565656;
}
.selectBox {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #339dff;
  border-top: none;
  background: #303030;

  box-sizing: border-box;

  transform: translateY(-30px);
}
.selectBox div {
  display: flex;
  width: 100%;
  height: 56px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-bottom: 1px solid #878787;

  cursor: pointer;
}

.selectBox div:last-child {
  border-bottom: none;
}

.selectBox div p {
  color: #fff;
  font-family: "Noto Sans CJK KR";
  font-size: 15px;

  font-weight: 500;
  line-height: 130%;

  position: relative;
}

.selectBox .closeBtn svg {
  width: 24px;
  height: 24px;
  color: #fff;
  position: absolute;
  top: 16px;
  right: 16px;
}

.optionSelectWrap {
  display: flex;
  padding: 16px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid #339dff;
  color: #339dff;
  cursor: pointer;

  z-index: 999;
  background-color: #222;
}

.optionBoxWrap {
  display: flex;
  padding: 24px 16px;
  /* flex-direction: column; */
  justify-content: space-between;
  align-self: stretch;

  border-radius: 8px;
  background: #323232;
}

.optionBoxWrap .price {
  text-align: right;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 998; /* Just below the bottomOrderSection */
}
