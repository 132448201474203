.cancelButton span {
  font-family: Pretendard, sans-serif;
  font-size: 11px;
  line-height: 13px;
  font-weight: 700;
  word-break: keep-all;
  color: #d03362;
  text-decoration: underline #d03362;
  text-underline-offset: 4px;
  display: inline-block;
  transition: transform 0.2s;
}
.cancelButton:hover {
  transform: translateY(-2px);
}

.orderTr {
  cursor: pointer;
}
.orderTr td {
  padding: 12px 0px;
  text-align: center;
}

.exSmallTh {
  width: 30px !important;
  text-align: center !important;
}

.smallTh {
  width: 50px !important;
  text-align: center !important;
}

.middleTh {
  width: 80px !important;
  text-align: center !important;
}

.largeTh {
  width: 160px !important;
  text-align: center !important;
}
