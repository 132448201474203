.wrap {
  flex: 1;
}
.mainWrap {
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}
.storageButtonWrap {
  display: flex;
  align-items: center;
  gap: 16px;
}

.storageButtonWrap button {
  width: 108px;
  height: 32px;

  padding: 8px 12px;
  gap: 8px;

  border: 1px solid var(--color-brand);
  border-radius: 4px;
  color: var(--color-brand);
  background-color: #fff;
}

.storageButtonWrap button.active {
  border: 1px solid var(--color-brand);
  color: #fff;
  background-color: var(--color-brand);
}

.headTilteSection {
  background-color: #fff;
  width: 100%;
  padding: 24px;
  margin-bottom: 16px;

  position: sticky;
  z-index: 999;
}

.headTilteSection > div {
  display: flex;
  align-items: center;
}

.headTilteSection > div h1 {
  flex: 1;

  font-family: "Pretendard Variable";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
}

.desc {
  color: #777;
  font-size: 16px;
  font-family: Roboto;
  line-height: 22px;

  position: relative;
  top: -12px;
}

.textarea {
  display: inline-flex;
  width: 100%;
  height: 458px;
  padding: 24px 24px 10px 24px;

  border-radius: 4px;
  background: #f2f2f2;
  box-sizing: border-box;
}

.formatButton {
  background-color: var(--color-brand);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  width: 80px;

  border-radius: 4px;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.mainWrap .titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
