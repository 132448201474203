.expand-box {
  height: auto;
  background-color: var(--dark-bg-color);
  border-radius: 10px;
  margin-bottom: 16px;

  color: var(--light-font-color);
}
.expand-box .title__wraps {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 14px 20px;
}
.expand-box .title__wraps .toggleImg {
  transform: rotate(-180deg);

  transition: all 0.4s ease-in-out;
}
.expand-box .expandInfo {
  width: 80%;
  padding: 0px 20px;
  opacity: 0;
}

.expand-box .toggleExpandInfo {
  padding-bottom: 66px;
  opacity: 1;

  transition: all 0.4s ease-in-out;
}
