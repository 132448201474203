.title {
  font-family: "Noto Sans KR-Bold";
  font-size: 24px;
  line-height: 1.75;
  color: #2d3748;
}

.inputWraps {
  display: flex;
  gap: 16px;

  padding: 24px 0px;
}
.inputWraps > div {
  flex: 1;
  display: flex;
  gap: 16px;

  flex-direction: column;
}
.inputWraps .inputWrap label {
  display: inline-block;
  margin-bottom: 8px;
}
.inputWraps .inputWrap input[type="text"],
.inputWraps .inputWrap select {
  border: 1px solid #d1d1d1;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  border-radius: 6px;
  resize: vertical;
  padding: 8px;
  box-sizing: border-box;
}

.inputWraps .inputWrap input[type="text"]:focus,
.inputWraps .inputWrap select:focus {
  border: 2px solid #667eea;
}

.uploadBtn {
  background-color: var(--color-brand);

  color: #fff;
  font-weight: bold;

  width: 100px;
  height: 30px;

  border-radius: 8px;
  margin-left: 16px;
}
.imagePreviewWrap {
  border-radius: 8px;
  border: 1px solid #d1d1d1;
}

.imagePreviewWrap img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}
.imagePreviewWrap p {
  width: 100%;
  padding-top: 24px;
  height: 250px;
  text-align: center;
  box-sizing: border-box;
  color: #d1d1d1;
  cursor: pointer;
}
.imagePreviewWrap p:hover {
  background-color: #f6f6f6;
}

.buttonWrap {
  display: flex;
  align-items: center;

  gap: 16px;
}

.buttonWrap button {
  background-color: var(--color-brand);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  height: 36px;
  width: 100%;
  /* padding: 8px 16px; */
  border-radius: 4px;
  min-width: fit-content;
}

.buttonWrap button.cancel {
  background-color: var(--color-grey);
  color: var(--color-text);
}

@media (max-width: 768px) {
  .inputWraps {
    flex-direction: column;
  }
}
