.changeButton {
  margin-left: 8px;
  font-size: 16px;
}
.changeButton:hover {
  color: var(--color-brand);
}

.exSmallTh {
  width: 30px !important;
}

.smallTh {
  width: 50px !important;
}

.middleTh {
  width: 80px !important;
}

.deleteButton {
  background-color: #fbeef2;
  color: #d03362;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  transition: all 0.1s;

  margin-left: 8px;
}
.deleteButton:hover {
  transform: scale(1.1);
}

.editButton {
  background-color: #e4faf3;
  color: #16b079;

  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  transition: all 0.1s;
}

.uploadButton {
  background-color: #f5eefb;
  color: var(--color-brand);

  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  transition: all 0.1s;
}
