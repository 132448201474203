.container {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 48px;
}

.header {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  margin-top: 70px;
  /* position: fixed; */
  /* background-color: var(--main-bg-color); */
}

.title {
  text-align: center;
  color: white;
  font-size: 18px;
  font-family: "Noto Sans KR-Bold";
}

.subtitle {
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  font-family: "Noto Sans KR-Medium";
  line-height: 140%;
  word-wrap: break-word;
}

.feedbacks {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-x: auto; /* 가로 스크롤 설정 */
  padding: 0 32px;
  box-sizing: border-box;
  /* margin-top: 160px; */
}
.feedbacks::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.feedback {
  width: 100%;
  height: fit-content;
  padding: 24px 16px;
  background: #2c2c2c;
  border-radius: 10px;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  flex-shrink: 0;
  box-sizing: border-box;
}

.stars {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  color: #ffc700;
}

.feedbackText p {
  color: white;
  font-size: 14px;
  font-family: "Noto Sans KR-Regular";
  line-height: 140%;
  word-break: keep-all;
  word-wrap: normal;
}
.feedbackFooter {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: white;
  font-size: 13px;
  font-family: "Noto Sans KR-Regular";
  line-height: 130%;
  word-wrap: break-word;
  word-wrap: normal;
}

.viewMore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.viewMoreButton {
  padding: 10px 40px;
  display: inline-flex;
  align-items: center;
  gap: 16px;
}

.viewMoreText {
  color: #339dff;
  font-size: 16px;
  font-family: "Pretendard Variable";
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}
