.deliveryInfoPage {
  padding: 0px 16px;
}

.deliveryInfoPage .orderSection {
  padding: 24px 0px;
}
.deliveryInfoPage .orderSection.lastSection {
  border-bottom: none;
}
.deliveryInfoPage .orderTitleWrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.deliveryInfoPage .orderTitleWrap h4 {
  font-family: "Noto Sans KR-Bold";
  font-size: 18px;
}
.deliveryInfoPage .orderTitleWrap p {
  font-family: "Noto Sans KR-Regular";
  font-size: 14px;
  line-height: 140%;
}

.deliveryInfoPage .orderDeilInputWraps {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
  border-top: 2px solid #ebebeb;
}

.deliveryInfoPage .receivePostcodeWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.deliveryInfoPage .orderDeilInputWrap > div {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #e1e1e1;
}

.deliveryInfoPage .orderDeilInputWrap,
.deliveryInfoPage .orderDeilSelectWrap {
  font-family: "Noto Sans KR-Regular";
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
}

.deliveryInfoPage .receivePostcodeWrap > div {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #e1e1e1;
}

.deliveryInfoPage .orderDeilInputWrap label.required::after,
.deliveryInfoPage .orderDeilSelectWrap label.required::after {
  content: "*";
  display: inline;
  color: var(--default-color);
  margin-left: 2px;
  vertical-align: middle;
}
.deliveryInfoPage .orderDeilInputWrap label,
.deliveryInfoPage .orderDeilSelectWrap label {
  min-width: 60px;
  width: 65px;
  flex-shrink: 0;
  color: #f5f5f7;
  font-size: 15px;
}
.deliveryInfoPage .orderDeilInputWrap input,
.deliveryInfoPage .orderDeilSelectWrap select {
  border: 1px solid #8f8f8f;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  resize: vertical;
  padding: 12px;
  box-sizing: border-box;
  background-color: transparent;
  color: #f5f5f7;
}
.deliveryInfoPage .orderDeilSelectWrap {
  position: relative;
}
.deliveryInfoPage .orderDeilSelectWrap::after {
  content: "";
  position: absolute;
  right: 16px;
  top: 32%;

  pointer-events: none;
  border: solid #69696b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.deliveryInfoPage .orderDeilSelectWrap select {
  color: #69696b;
  -moz-appearance: none; /* 파이어폭스에서 기본 화살표 제거 */
  -webkit-appearance: none; /* 크롬, 사파리에서 기본 화살표 제거 */
  appearance: none; /* 최신 브라우저에서 기본 화살표 제거 */
}

/* 배포 후 확인해서 적용 안되면 수정 */
.deliveryInfoPage .deliMemo option {
  width: 100%;
  background-color: #000;
  color: #fff;
}

.deliveryInfoPage .addrInputWrap > div {
  display: flex;
  align-items: center;
  gap: 8px;
}
.deliveryInfoPage .orderDeilInputWrap .postcodeBtn {
  font-family: "Noto Sans KR-Medium";
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: 80px;
  padding: 12px 0px;
  font-size: 14px;
  color: var(--default-color);
  background-color: #383838;
  border-radius: 4px;
}

.deliveryInfoPage .orderBottomBtn button {
  font-family: "Noto Sans KR-Bold";
  font-size: 16px;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  /* padding: 16px 0px 24px; */
  margin: 0px 16px;
  margin-bottom: 56px;
  height: 60px;
  background-color: var(--default-color);
  color: #fff;
  border-radius: 10px;
}

.deliveryInfoPage .orderShopInfoWrap {
  border-top: 1px solid #ebebeb;
  margin-top: 24px;
  padding: 16px 0px;
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.deliveryInfoPage .orderShopInfoWrap h6 {
  font-size: 13px;
  color: #373738;
  margin-bottom: 8px;
}

.deliveryInfoPage .orderShopInfoWrap p.orderShopInfoDesc {
  margin-bottom: 16px;
  font-family: "Noto Sans KR-Bold";
  font-size: 14px;
}
.deliveryInfoPage .orderShopInfoWrap p.orderShopInfoOption {
  margin-bottom: 8px;
  color: #373738;
  font-family: "Noto Sans KR-Regular";
  font-size: 13px;
}
.deliveryInfoPage .orderShopInfoWrap p.orderShopInfoPrice {
  margin-bottom: 16px;
  color: var(--default-color);
  font-family: "Noto Sans KR-Medium";
  font-size: 14px;
  line-height: 1.45;
}

.deliveryInfoPage .orderShopInfoWrap img {
  width: 100px;
  height: 90px;
  object-fit: cover;
}

.deliveryInfoPage .orderPriceInfoWrap {
  border-top: 1px solid #ebebeb;
  margin-top: 24px;
  padding: 24px 0px 40px 0px;
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: center;
}

.deliveryInfoPage .orderPriceInfoWrap .priceWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.deliveryInfoPage .orderPriceInfoWrap .priceWrap h6 {
  color: #000;
  text-align: center;
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 8px;
}

.deliveryInfoPage .orderPriceInfoWrap .priceWrap span {
  color: #000;
  text-align: center;
  font-family: "Noto Sans KR";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 18px */
}
