.board-round-main_btn {
  width: 100%;
  max-width: 500px;
  height: 44px;

  margin: auto;
  border-radius: 10px;

  background-color: var(--button-main-color);
  color: #ffffff;
}
.board-round-main_btn.disable {
  background-color: var(--disable-bg-color);
}
.board-round-main_btn.disable h6 {
  color: var(--disable-font-color) !important;
}
