.container {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* gap: 48px; */
  margin: 72px 0 60px 0;
}

.header {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}

.title {
  text-align: center;
  color: white;
  font-size: 18px;
  font-family: "Noto Sans KR-Bold";
}

.subtitle {
  text-align: center;
  color: #d8d8d8;
  font-size: 14px;
  font-family: "Noto Sans KR-Medium";
  line-height: 140%;
  word-wrap: break-word;
  margin-bottom: 48px;
}

.feedbacks {
  width: 100%;
  display: flex;
  flex-direction: row; /* 가로 방향으로 설정 */
  gap: 24px;
  overflow-x: auto; /* 가로 스크롤 설정 */
  padding: 0 32px;
  box-sizing: border-box;
}
.feedbacks::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.feedback {
  width: 240px; /* 고정된 너비 */
  height: 265px; /* 고정된 높이 */
  padding: 24px 16px;
  background: #292929;
  border-radius: 10px;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  flex-shrink: 0;
  box-sizing: border-box;
}

.stars {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  color: #ffc700;
}
.feedbackText {
  height: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.feedbackText p {
  color: white;
  font-size: 14px;
  font-family: "Noto Sans KR-Regular";
  line-height: 140%;
  word-break: keep-all;
  word-wrap: normal;
}
.feedbackFooter {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: white;
  font-size: 13px;
  font-family: "Noto Sans KR-Regular";
  line-height: 130%;
  word-wrap: break-word;
  word-wrap: normal;
}

.viewMore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: 24px;
}

.viewMoreButton {
  padding: 10px 32px;
  display: inline-flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  background-color: #292929;
  border-radius: 50px;
}

.viewMoreText {
  color: #fff;
  font-size: 16px;
  font-family: "Noto Sans KR-Mideum";
  line-height: 24px;
  word-wrap: break-word;
}
