.categoryNav {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e7eb;
  gap: 30px;

  margin-top: 16px;
  margin-bottom: 32px;
}

.categoryNav a {
  color: #6a717e;
  padding: 14px 0px;
}

.categoryNav a:hover {
  color: #374151;
  font-weight: 600;
  border-bottom: 2px solid #d1d5db;
}

.categoryNav a.active {
  color: var(--color-brand);
  font-weight: 600;

  border-bottom: 2px solid var(--color-brand);
}

.mainWrap {
  display: flex;
  gap: 24px;
}

.categoryWrap {
  width: 160px;
  height: fit-content;

  max-height: 400px;

  color: #212121;
  border-radius: 4px;

  flex-shrink: 0;

  border-bottom: 1px solid #d1d5db;
}

.categoryBtn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  padding: 0px;
  padding-bottom: 12px;
  font-size: 14px;
  color: #6b7280;
  transition: color 0.3s;
}

.categoryBtn:hover {
  color: #4b5563;
}

.buttonText {
  font-weight: 500;
  color: #2d3748;
}

.buttonIcon {
  margin-left: 24px;
  display: flex;
  align-items: center;
}

.iconSvg {
  height: 20px;
  width: 20px;
}

.categoryWrap .categorList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;
  padding-bottom: 24px;
}

.categoryWrap .categorList .flexWrap {
  display: flex;
  align-items: center;
}
.categoryWrap .categorList .flexWrap .checkboxInput {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  border: 1px solid #d1d5db;
  color: #4c51bf;
  transition: box-shadow 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.categoryWrap .categorList .flexWrap .checkboxInput:focus {
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.5);
  border-color: #4c51bf;
}
.categoryWrap .categorList .flexWrap .checkboxLabel {
  margin-left: 12px;
  font-size: 14px;
  color: #6b7280;
}
.categoryWrap .categorList .active {
  background-color: var(--color-menu);
  color: var(--color-brand);

  font-weight: 700;
}

.categoryWrap .categorList .total {
  padding-left: 26px;
}

.templateCardWrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 16px;
  row-gap: 24px;
}

@media (max-width: 1400px) {
  .templateCardWrap {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1200px) {
  .templateCardWrap {
    grid-template-columns: repeat(3, 1fr);
  }
  .mainWrap {
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  .templateCardWrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .templateCardWrap {
    grid-template-columns: repeat(1, 1fr);
  }
}
