.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  flex-shrink: 0;
  padding: 30px 30px 0;
  margin: 0;
}

.header > div {
  display: flex;
  justify-content: space-between;
}

.header > div h1 {
  font-family: Pretendard, sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 900;
  word-break: keep-all;
  margin: 0 0 20px;
  padding: 0;
  color: #000;
}

.header nav {
  display: flex;
  column-gap: 16px;
  margin: 0 -30px;
  padding: 0 30px;
  border-bottom: 1px solid #f5f5f5;
}

.header nav button {
  flex-shrink: 0;
  display: inline-block;
  color: #bfbfbf;
  cursor: pointer;
  border: 0;
  border-radius: unset;
  padding: 0 0 5px;

  font-weight: 700;
  font-size: 14px;
  line-height: 22.4px;

  font-family: Pretendard, sans-serif;
}

.header nav button.active {
  color: #000;
  box-shadow: inset 0 -2px #000;
}

.mainWrap {
  flex-grow: 1;
  padding: 0 30px;
  margin: 0;

  padding-top: 24px;
  padding-bottom: 40px;
  overflow-y: auto;
  height: 100%;
}

.mainWrap > div {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.mainWrap .title {
  font-weight: 700;
  font-size: 14px;
  font-family: Pretendard, sans-serif;
  line-height: 22.4px;
}

.mainWrap table {
  table-layout: fixed;
  width: 100%;
  overflow-x: auto;
}

.mainWrap table {
  width: 100%;
  table-layout: initial;
  min-width: 500px;
  overflow-x: auto;
}
.mainWrap table tr {
  position: relative;
  border-bottom: 1px solid #f5f5f5;
  background-color: #fff;
}

.mainWrap table tr th {
  font-family: Pretendard, sans-serif;
  font-size: 12px;
  line-height: 19.2px;
  font-weight: 700;
  text-align: left;
  word-break: break-word;
  color: #8c8c8c;

  width: 120px;

  background-color: #fbfbfb;
  padding: 17px 16px;
}

.mainWrap table tr td {
  font-family: Pretendard, sans-serif;
  font-size: 12px;
  line-height: 19.2px;
  font-weight: 400;
  text-align: left;
  word-break: break-word;
  color: #8c8c8c;
  white-space: nowrap;

  color: #000;
  padding: 15px 16px;
}

.customerWrap,
.paymentWrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.paymentWrap .titleWrap {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-family: Pretendard, sans-serif;
}

.paymentWrap .titleWrap .title {
  flex: 1;
}

.paymentWrap .titleWrap p {
  font-family: Pretendard, sans-serif;
  font-size: 12px;
  line-height: 19.2px;
  font-weight: 400;
  word-break: keep-all;
  max-width: 400px;
  overflow: hidden;
  color: #8c8c8c;

  cursor: pointer;
}

.paymentWrap .infoWrap {
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  padding: 20px 20px 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.paymentWrap .infoWrap .infoTitleWrap {
  display: flex;
  margin-bottom: 12px;
}

.paymentWrap .infoWrap .infoTitleWrap .leftWrap {
  flex: 1;
}

.paymentWrap .infoWrap .infoTitleWrap .leftWrap h1 {
  font-family: Pretendard, sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 700;
  word-break: keep-all;
  margin: 0 0 4px;
  color: #000;
}
.paymentWrap .infoWrap .infoTitleWrap .leftWrap h2 {
  font-family: Gotham A, Gotham B, sans-serif;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  word-break: keep-all;
  margin: 0;
  color: #bfbfbf;
}

.paymentWrap .infoWrap .infoTitleWrap .rightWrap {
  flex-shrink: 0;
  padding-top: 4px;
  display: flex;
  flex-direction: row;
  column-gap: 6px;
}

.paymentWrap .infoWrap .infoTitleWrap .rightWrap button {
  font-family: Pretendard, sans-serif;
  font-size: 12px;
  line-height: 19.2px;
  font-weight: 700;
  word-break: keep-all;

  background-color: #f5f5f5;
  height: 30px;
  padding: 0 12px;
  border-radius: 6px;
  cursor: pointer;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;

  padding: 20px 30px;
  margin: 0;
  border-top: 1px solid #f5f5f5;
}

.footer button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  padding: 0 14px;
  height: 40px;

  font-family: Pretendard, sans-serif;
  font-size: 14px;

  word-break: keep-all;

  color: #fff;
  background-color: #000;
}

.footer button span {
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer button:first-child {
  background-color: #f5f5f5;
  color: initial;
}

/* 결제 취소 css */
.cancelTab {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.cancelTab .inputLabelWrap {
  width: 330px;
  display: flex;
  flex-direction: column;
}

.cancelTab .inputLabelWrap label {
  font-family: Pretendard, sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  word-break: keep-all;
  margin-bottom: 11px;
  color: #000;
}

.cancelTab .inputLabelWrap > div {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.cancelTab .required {
  color: red;
}

.cancelTab .inputHintGroup {
  flex: 0 0 330px;
  display: flex;
  flex-direction: column;
  row-gap: 9px;
}
.cancelTab .inputContainer {
  width: 330px;
  display: flex;
  flex-direction: column;
}
.cancelTab .inputWrap {
  display: flex;
  align-items: center;
  column-gap: 8px;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #eaeaea;
  height: 50px;
}

.cancelTab .inputWrap input {
  text-align: right;
  padding-right: 0;

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  word-break: keep-all;
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 20px;
  color: #000;
  border: none;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cancelTab .inputWrap span {
  margin-right: 20px;

  font-family: Gotham A, Gotham B, sans-serif;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  word-break: keep-all;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.cancelTab .hintContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cancelTab .hintContainer .hintText {
  font-weight: 400;
  font-size: 12px;
  line-height: 19.2px;
  color: #8c8c8c;
  font-family: Pretendard, sans-serif;

  display: inline;
  word-break: keep-all;
}
.cancelTab .hintContainer .amountWrap span {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;

  font-family: Pretendard, sans-serif;
  display: inline;
  word-break: keep-all;
}
.cancelTab .amountBtnGroup {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}
.cancelTab .amountBtnGroup button {
  font-family: Pretendard, sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  word-break: keep-all;

  color: #a7a7a7;
  border: 1px solid #eaeaea;
  background-color: #fff;

  display: inline-flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: transform 0.2s;
  white-space: nowrap;

  height: 50px;
  padding: 0 13px;
}
.cancelTab .amountBtnGroup button.active {
  color: #000;
  border-color: #000;
  font-weight: 700;
}

.cancelTab .amountBtnGroup button span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancelCofirmBtn {
  margin-left: 16px;
}

.details {
  position: relative;
}

.details summary {
  list-style: none;
  column-gap: 8px;
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  border: 1px solid #eaeaea;
  text-align: left;

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  word-break: keep-all;
  height: 40px;
  padding: 0 10px 0 16px;

  width: 330px;
  box-sizing: border-box;
}

.details summary span {
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.details summary svg {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.details[open] summary svg {
  transform: rotate(180deg);
}

.bankMenuWrap {
  position: absolute;
  display: flex;
  box-shadow: 0 3px 5px #0000001a;
  background-color: #fff;
  margin-top: 8px;
  border-radius: 5px;
  z-index: 12;
}

.bankMenuWrap > div {
  width: 330px;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 4px;
  overflow-y: auto;
  overflow-x: hidden;

  max-height: 220px;
}

.bankMenuWrap > div .dropDownItem {
  height: 36px;
  padding: 0 14px;
  margin: 0 2px;

  display: flex;
  column-gap: 12px;
  align-items: center;
  border-radius: 6px;
  background-color: #fff;
  user-select: none;
  cursor: pointer;
}
.bankMenuWrap > div .dropDownItem:hover {
  background-color: #f5f5f5;
}

.bankMenuWrap > div .dropDownItem span {
  flex-grow: 1;

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  word-break: keep-all;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.bankMenuWrap > div .dropDownItem span.active {
  font-weight: 700;
}
