.cardWrap {
  background-color: #ffffff;
  min-width: min-content;
  margin: 0 1rem;
  border-radius: 4px;

  position: relative;
}

.cardWrap img {
  width: 100%;
  height: 160px;

  object-fit: cover;
}

.infoWrap {
  margin: 16px;
}

.infoWrap h3 {
  font-size: 18px;
  font-weight: 600;

  color: var(--color-brand);
}
.infoWrap h4 {
  font-size: 14px;
}

.typeWrap {
  margin: 16px 0px 8px 0px;
}
.typeWrap span:first-child {
  margin-right: 16px;
  color: #4b5563;
}
.typeWrap span {
  font-family: "Noto Sans KR-Regular";
}

.flexWrap {
  display: flex;
  align-items: center;
  gap: 4px;
}

.flexWrap span {
  font-size: 15px;
  font-family: "Noto Sans KR-Regular";
  color: #4b5563;
}

.makeButtonWrap {
  position: absolute;
  top: 0;
  width: 100%;
  height: 160px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.1);
}
.makeButton {
  width: 80%;
  background-color: var(--color-brand);
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 4px;

  text-align: center;

  margin: 0 auto 14px;
  transition: background-color 0.15s ease-in-out;
}

.makeButton:active {
  background-color: var(--color-brand);
}

@media (min-width: 768px) {
  .cardWrap {
    margin: 0;
  }
}
