.main-notice_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.main-notice_section img {
  display: block;
}

.main-notice_section p {
  color: var(--light-font-color);
  text-align: center;
}

.funtion-photo_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: flex-end; */
  flex-wrap: wrap;
  gap: 32px;

  border-radius: 10px;
  padding: 24px;
  margin-bottom: 20px;

  font-family: var(--default-font-style);

  text-align: start;
}
.funtion-photo_card h4 {
  font-family: "Noto Sans KR-bold";
  color: var(--default-color);
  margin-bottom: 4px;
  font-size: 14px;
}
.funtion-photo_card h6 {
  font-family: "Noto Sans KR-bold";
  font-size: 24px;
}
.funtion-photo_card pre {
  font-family: "Noto Sans KR-Light";
  font-weight: 200;
  line-height: 130%;
  margin-top: 8px;
  font-size: 15px;
  color: #d8d8d8;
}
.funtion-photo_card .card-image {
  display: flex;
  justify-content: center;
}
.funtion-photo_card .card-image img {
  width: 70%;
}

.template_card {
  background-color: var(--dark-light-bg-color);
  margin-bottom: 32px;

  border-radius: 10px;
  min-width: 360px;
}
.template_card .sample_video {
  width: 100%;
  height: 202px;
  border-radius: 10px 10px 0px 0px;
  background: #000;
}
.template_card .thum-img {
  width: 100%;
  height: 175px;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}
.template_card main {
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 24px;
}
.template_card .title_wraps {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0px;
}

.template_card .info_wraps {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.template_card .info_wraps div {
  display: flex;
  align-items: center;
  gap: 8px;
}
.template_card .info_wraps div span {
  font-size: 14px;
}

@media (max-width: 768px) {
  .template_card {
    margin-bottom: 32px;
    border-radius: 10px;
    min-width: initial;
  }
}
