.cancelButton span {
  font-family: Pretendard, sans-serif;
  font-size: 11px;
  line-height: 13px;
  font-weight: 700;
  word-break: keep-all;
  color: #d03362;
  text-decoration: underline #d03362;
  text-underline-offset: 4px;
  display: inline-block;
  transition: transform 0.2s;
}
.cancelButton:hover {
  transform: translateY(-2px);
}

.thPaidDt {
  width: 6%;
  text-align: center !important;
}

.thOrderNum {
  width: 6%;
  text-align: center !important;
}

.thOrder {
  width: 10%;
  text-align: center !important;
}
.thShop {
  width: 5%;
  text-align: center !important;
}
.thShopPrice {
  width: 4%;
  text-align: center !important;
}
.thShopQual {
  width: 2%;
  text-align: center !important;
}
.thAmount {
  width: 5%;
  text-align: center !important;
}

.thMethod {
  width: 5%;
  text-align: center !important;
}

.thState {
  width: 5%;
  text-align: center !important;
}

.thCancel {
  width: 2%;
  text-align: center !important;
}

.orderTr {
  cursor: pointer;
}
.orderTr td {
  padding: 12px 0px;
  text-align: center;
}
