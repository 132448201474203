.iconWrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.userIcon {
  margin-bottom: 24px;
}
.title {
  font-family: "Noto Sans KR-Medium";
  font-size: 18px;
  color: #fff;
  margin-bottom: 16px;
}

.desc {
  font-family: "Noto Sans KR-Light";
  font-size: 14px;
  color: var(--light-font-color);
  line-height: 140%;
}

.buttonWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 32px;
  margin-bottom: 20px;
}

.buttonWrap button {
  background: #fddc3f;

  color: #383838;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  width: 100%;

  border-radius: 8px;

  min-width: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
