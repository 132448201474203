.article {
  position: relative;

  width: 659px;
  height: 750px;
  margin: auto;
  display: flex;
  align-items: center;

  background-color: #000;
}

.thumImg {
  width: 100%;
  position: absolute;
}
