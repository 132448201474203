/*  이미지 리스트 div */
.image_scene {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  margin: 24px 0;
  height: 164px;
  border-radius: 4px;
  white-space: nowrap;
  overflow: auto;
  overflow-y: hidden;
  padding: 5px 15px 0px 0px;
}

/* 이미지와 인덱스를 감싸는 div */
.image_scene_list,
.image_scene_contents {
  display: inline;
  align-items: center;
  margin: 24px 0;
  width: 100%;
  height: auto;
  overflow-y: hidden;
  padding: 5px 15px 0px 0px;
  position: relative;
}
/* 인덱스 div */
.image_scene_index {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 30px;
  top: -6px;
  left: 20px;
  height: auto;
  color: #ffffff;
  text-align: center;
  font-family: var(--noto4);
  font-size: 12px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
}
/* 이미지 리스트 img css*/
.image_scene img {
  width: 218px;
  height: 124px;
  margin: 0px 0 0 15px;
  cursor: pointer;
  object-fit: cover;
}

@media (max-width: 1200px) {
  /* 텍스트 이미지 리스트 div */
  .image_scene {
    margin: 24px 0;
    height: 164px;
  }

  /* 이미지와 인덱스를 감싸는 div */
  .image_scene_list,
  .image_scene_contents {
  }
  /* 인덱스 div */
  .image_scene_index {
    width: 30px;
    top: -6px;
    left: 23px;
    font-size: 12px;
    padding: 5px;
  }
  /* 텍스트 이미지 리스트 img css*/
  .image_scene img {
    margin: 0 0 0 18px;
  }
}

@media (max-width: 1000px) {
  /* 텍스트 이미지 리스트 div */
  .image_scene {
    margin: 24px 0;
    height: 164px;
    width: 700px;
    border-radius: 4px;
  }

  /* 이미지와 인덱스를 감싸는 div */
  .image_scene_list,
  .image_scene_contents {
  }
  /* 인덱스 div */
  .image_scene_index {
    width: 30px;
    top: -6px;
    left: 23px;
    height: auto;
    font-size: 12px;
  }
  /* 텍스트 이미지 리스트 img css*/
  .image_scene img {
    width: 218px;
    height: 124px;
    margin: 0 0 0 18px;
    /* padding: 0 0 0 18px; */
  }
}

@media (max-width: 600px) {
  /* 텍스트 이미지 리스트 div */
  .image_scene {
    margin: 24px 16px 16px;
    padding: 0px 14px;
    width: 343px;
    height: 100px;
  }
  /* 이미지와 인덱스를 감싸는 div */
  .image_scene_list,
  .image_scene_contents {
  }
  /* 인덱스 div */
  .image_scene_index {
    width: 20px;
    top: -6px;
    left: 10px;
    font-size: 12px;
  }
  /* 텍스트 이미지 리스트 img css*/
  .image_scene img {
    width: 124px;
    height: 70px;
    margin: 0px 5px;
    padding: 0px;
  }
}
