.funcSection {
  margin-top: 16px;
}

.searchWrap {
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 12px;
}
.searchWrap form {
  flex: 1;
}

.searchWrap .updateButton {
  display: inline-flex;

  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  background-color: white;
  border-color: #d1d5db;
  color: #374151;
  margin-left: 0;
}
.searchWrap .updateButton svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.filterWraps {
  display: flex;
  gap: 16px;
}

.filterWraps nav {
  display: flex;
  gap: 16px;
  overflow-x: auto;
}

.filterWraps nav button {
  display: inline-flex;
  flex-shrink: 0;
  gap: 4px;
  align-items: center;
  padding-bottom: 6px;

  cursor: pointer;
  border: 0;
  border-radius: unset;

  color: #bfbfbf;
}

.filterWraps nav button.active {
  box-shadow: inset 0 -2px #000;
  color: #000;
}

.filterWraps nav .text {
  font-weight: 700;
  font-size: 14px;

  font-family: Pretendard, sans-serif;
}
.tag {
  height: 18px;
  padding: 0px 6px;
  border-radius: 6px;
  font-size: 12px;

  font-weight: 500;
  word-break: keep-all;

  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.tag.blackTag {
  background-color: #262626;
  color: #fff;
}

.tag.greenTag {
  background-color: #e4faf3;
  color: #16b079;
}
.tag.blueTag {
  background-color: #e3f5ff;
  color: #219dd1;
}
.tag.indigoTag {
  background-color: #efe3ff;
  color: var(--color-brand);
}
.tag.redTag {
  background-color: #fbeef2;
  color: #d03362;
}
.tag.yellowTag {
  background-color: #f9f3e5;
  color: #d19b0f;
}

.tag.greyTag {
  background-color: #eff0f1;
  color: #676a6c;
}

.uid {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.smallTh {
  width: 50px !important;
}

.cancelButton span {
  font-family: Pretendard, sans-serif;
  font-size: 11px;
  line-height: 13px;
  font-weight: 700;
  word-break: keep-all;
  color: #d03362;
  text-decoration: underline #d03362;
  text-underline-offset: 4px;
  display: inline-block;
  transition: transform 0.2s;
  cursor: pointer;
}
.cancelButton:hover {
  transform: translateY(-2px);
}
.detailsButton {
  background-color: #f5eefb;
  color: var(--color-brand);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  transition: all 0.1s;
}
