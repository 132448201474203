.article {
  position: relative;

  width: 659px;
  height: 450px;
  margin: auto;
  display: flex;
  align-items: center;

  background-color: #d1d5db;
}

.thumImg {
  width: 100%;
  position: absolute;
}
