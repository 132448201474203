.mainWrap {
  margin-top: 24px;
  gap: 16px;
  display: flex;
}

.mainWrap .infoWrap {
  width: 100%;
  flex: 2.5;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mainWrap .infoWrap article {
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
}

.mainWrap .infoWrap article .asset_thumb {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.mainWrap .infoWrap .editButton {
  border: 1px solid var(--color-brand);
  color: var(--color-brand);
  font-weight: bold;
  width: 100%;
  min-width: 40px;
  height: 30px;
  border-radius: 8px;
  margin-top: 10px;
}

.infoTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-brand);
  white-space: nowrap;
}

.basicInfoWrap {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 280px;
}

.basicInfoWrap > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.basicInfoWrap > div .titleWrap {
  flex: 1;
  width: 100%;
}

.basicInfoWrap > div .titleWrap h6 {
  font-size: 14px;
  color: #b0b4bc;
  margin-bottom: 8px;
}

.mainWrap .previewWrap {
  width: 100%;
  height: 500px;
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mainWrap .previewWrap .actionsWrap {
  width: 100%;
  height: 400px;
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
}

.mainWrap .previewWrap .actionsWrap h6 {
  color: #b0b4bc;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  margin-bottom: 4px;
}

.mainWrap .previewWrap .actionsWrap span {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  margin-bottom: 4px;
}

.exSmallTh {
  width: 30px !important;
}

.smallTh {
  width: 50px !important;
}

.middleTh {
  width: 80px !important;
}

.largeTh {
  width: 100px !important;
}

@media (max-width: 1200px) {
  .mainWrap {
    flex-direction: column-reverse;
  }
}
