.funcSection {
  margin-top: 16px;
}

.searchWrap {
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 12px;
}
.searchWrap form {
  flex: 1;
}

.mainWrap {
  margin-top: 24px;
}

.exSmallTh {
  width: 30px !important;
  text-align: center !important;
}

.smallTh {
  width: 50px !important;
  text-align: center !important;
}

.middleTh {
  width: 80px !important;
  text-align: center !important;
}

.largeTh {
  width: 160px !important;
  text-align: center !important;
}

.summaryRow td {
  padding: 12px 16px;
  text-align: center;
}
