.footer {
  width: 100%;
  background: transparent;
  padding: 40px 24px 56px 24px;
  box-sizing: border-box;
  background-color: #1e1e1e;
}

.footer .ul {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.divider {
  width: 1px;
  height: 12px;
  background: #fff;
  margin: 0 16px;
}

.toggleButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;

  font-family: "Noto Sans KR-Bold";
  font-size: 15px;
  font-weight: 500;
}
.footer .ul a {
  color: #fff;

  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 500;
}

.footer > div {
  max-width: var(--maxwidth);
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 40px;

  margin: auto;
}
.toggleSections {
  width: 100%;
}
.footer section {
  /* width: 100%; */
}
.footer section:last-child {
  margin-top: 32px;
}

.footer section h4 {
  margin-bottom: 24px;
  color: #fff;
  font-family: "Noto Sans KR";
  /* font-size: 18px; */
  /* font-weight: 900; */
  word-break: auto-phrase;

  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 900;
}
.footer .titleWrap h6 {
  color: #fff;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-weight: 900;
  display: inline;
  word-break: auto-phrase;

  font-size: 13px;
  font-weight: 500;
}
.footer .titleWrap span {
  color: #fff;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-weight: 400;
  word-break: auto-phrase;

  font-size: 13px;
  font-weight: 400;
}

.footer .titleWrap {
  display: flex;
  gap: 8px;
  /* align-items: center; */

  align-items: start;
}

.footer section .companyDetails > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
}

.footer section .companyDetails .titleWrap:first-child {
  margin-right: 18px;
}

.footer section .contactDetails {
  margin-bottom: 32px;
}
.footer section .contactDetails > div {
  display: flex;

  gap: 8px;
  margin-bottom: 8px;
}

.footer section .contactDetails .titleWrap:first-child {
  margin-right: 78px;
}

@media (max-width: 768px) {
}

/* 주문완료 페이지 */
.footer.orderComplete {
  background: #f4f4f4;
}
.footer.orderComplete .ul a {
  color: #8f8f8f;
}

.footer.orderComplete section h4 {
  color: #8f8f8f;
}

.footer.orderComplete .titleWrap h6,
.footer.orderComplete .titleWrap span {
  color: #8f8f8f;
}

.footer.orderComplete .companyDetails > div,
.footer.orderComplete .contactDetails > div {
  color: #8f8f8f;
}

.footer.orderComplete .divider {
  background: #8f8f8f;
}

/* 홈페이지 */
.footer.home {
  background: #000;
}
.footer.home .ul a {
  color: #fff;
}

.footer.home section h4 {
  color: #fff;
}

.footer.home .titleWrap h6,
.footer.home .titleWrap span {
  color: #fff;
}

.footer.home .companyDetails > div,
.footer.home .contactDetails > div {
  color: #fff;
}
