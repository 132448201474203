.service-help_wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}
.service-help_image {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}
#service-page {
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 186px;
}

#service-page .main-notice_section > div {
  width: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#service-page .main-notice_section button {
  width: 100%;
  height: 44px;
  border-radius: 10px;
  background-color: var(--dark-bg-color);
}

#service-page .main-notice_section button p {
  color: var(--default-color);
}

#service-page .basic-title__wraps {
  margin-bottom: 24px;
}

#service-upload {
  padding-left: 16px;
  padding-right: 16px;
  /* padding-bottom: 186px; */
  /* position: fixed; */
}

#service-upload.uploading_wrap {
  padding-top: 56px;
}

#service-upload.uploading_wrap .main-notice_section {
  gap: 16px;
}
#service-upload.uploading_wrap h1 {
  font-size: 20px;
}
#service-upload.uploading_wrap img {
  width: 191.638px;
  height: 191.638px;
}

#service-upload.uploading_wrap p {
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
}

#service-upload.uploading_wrap b {
  color: #f93232;
}

#service-upload .share-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* 
  position: sticky;
  top: 62px; */
  background: var(--main-bg-color);
  z-index: 999;
}

#service-upload > main {
  margin-top: 36px;
}

#service-complete-page.modifying_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

#service-complete-page.modifying_wrap .video_wrap {
  width: 100%;
  max-width: 500px;

  height: 202px;
  flex-shrink: 0;
  margin-top: 16px;
}

#service-complete-page.modifying_wrap .video_wrap iframe {
  width: 100%;
  height: 202px;
  max-width: 500px;
  background: #000;
}

#service-complete-page.photo-lack_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  padding-top: 56px;
}

#service-complete-page .main-image_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  width: 100%;
}

#service-complete-page .main-image_wrap h1 b {
  color: var(--default-color);
  font-family: "Noto Sans KR";
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
}
#service-complete-page .main-image_wrap img {
  width: 170.902px;
  height: 170.902px;
  object-fit: none;
}

#service-complete-page .main-image_wrap p {
  color: #d8d8d8;
  text-align: center;
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
}

#service-complete-page .tip-section {
  display: flex;
  width: 100%;
  max-width: 500px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  margin-top: 16px;

  border-radius: 10px;
  background: #2c2c2c;

  box-sizing: border-box;
}

#service-complete-page .tip-section .title_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#service-complete-page .tip-section .title_wrap .play_btn {
  box-sizing: border-box;
  padding: 10px;

  border-radius: 10px;
  background: #214369;

  cursor: pointer;
}

#service-complete-page .tip-section h6 {
  color: var(--Text-White, #fff);

  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
}

#service-complete-page .tip-section p {
  color: var(--Text-light-gray, #d8d8d8);

  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
}

#service-complete-page .tip-section p span {
  color: #ffc700;
}

#service-complete-page .tip-section a {
  color: var(--default-color);

  /* Body/noto 14 Regular */
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */

  display: flex;
  align-items: center;
}

#service-complete-page .tip-section .tip-bottom_btns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

#service-complete-page .tip-section .tip-bottom_btns button {
  height: 48px;
  padding: 10px 8px 10px 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 10px;
  background: #214369;

  color: var(--default-color);
  font-size: 14px;

  font-family: "Noto Sans CJK KR";

  font-weight: 700;
  line-height: 140%; /* 19.6px */
}

#service-complete-page .tip-section button.kakao_btn {
  background: #fddc3f;

  color: #383838 !important;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  width: 100%;

  border-radius: 8px;

  min-width: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

#service-complete-page .tip-section button.kakao_btn a {
  color: #383838 !important;
}
#service-complete-page .tip-section .tip-bottom_btns button:last-child {
  background: var(--button-main-color);

  color: var(--default-font-color, #fff);
}

/* 서비스 이용후기 */
#service-complete-page .feedback-section {
  display: flex;
  width: 100%;
  max-width: 500px;
  padding: 24px 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  margin-top: 16px;

  border-radius: 10px;
  background: #2c2c2c;

  box-sizing: border-box;
}

#service-complete-page .feedback-section .title_wrap {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

#service-complete-page .feedback-section .stars {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}

#service-complete-page .feedback-section .stars button svg {
  color: #ffc700;
  width: 24px;
  height: 24px;
}
#service-complete-page .feedback-textarea_wrap {
  width: 100%;
  margin-top: 8px;
}
#service-complete-page .feedback-textarea_wrap textarea {
  width: 100%;
  height: 150px;
  padding: 16px 12px;

  border-radius: 4px;
  border: 1px solid #777;
  background: #2c2c2c;

  box-sizing: border-box;

  color: #fff;
}

#service-complete-page .feedback-textarea_wrap textarea:focus {
  outline: none;
}
#service-complete-page .feedback-textarea_wrap button {
  width: 100%;
  height: 44px;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: 8px;
  background: #373737;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: #777;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  margin-top: 16px;
}

#service-complete-page .feedback-textarea_wrap button.active {
  background: var(--default-color);
  color: #fff;
}

#service-complete-page .add-upload_btn {
  height: 48px;
  width: 100%;
  max-width: 280px;
  padding: 10px 8px 10px 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 10px;
  background: #214369;

  color: var(--default-color);
  font-size: 14px;
  font-family: "Noto Sans CJK KR";
  font-weight: 700;
  line-height: 140%; /* 19.6px */

  margin: auto;
  margin-top: 40px;
}

#service-complete-page .tip-section .tip-bottom_btns button.disable {
  background: #373737;

  color: #777;
}

#service-complete-page .main-notice_section {
  padding-top: 56px;
  gap: 0px;
}
#service-complete-page .main-notice_section .title_wrap {
  text-align: center;
}
#service-complete-page .main-notice_section .title_wrap h1 {
  margin-bottom: 10px;
}
#service-complete-page .main-notice_section .title_wrap p {
  color: #d8d8d8;
}

#service-complete-page .main-notice_section .image_wrap p {
  color: #d8d8d8;
  text-align: center;
  font-family: "Noto Sans KR";
  font-size: 16px;

  font-weight: 400;
  line-height: 160%; /* 16px */
}

#service-complete-page .main-notice_section img {
  margin-top: 54px;
  margin-bottom: 64px;
}

/* infoCheck 정보확인 css */
#service-info-check {
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
}

#service-info-check > main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 58px;
}

#service-info-check .info-input_section {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

#service-info-check .info-input_section label {
  display: block;
  margin-bottom: 4px;
  font-size: 13px;
  color: #9e9e9e;
  /* color: var(--default-color); */
}

#service-info-check .info-input_section input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  font-size: 16px;
  padding: 14px 10px;
  margin-bottom: 16px;
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  transition: border-color 0.4s;
  border-radius: 0px;
}

#service-info-check .info-input_section input:focus {
  outline: none; /* 포커스 시 아웃라인 제거 */
  border-bottom-color: var(--default-color);
}

#service-template-check {
  color: #fff;
}

#service-template-check > main {
  margin-top: 36px;
}
#service-template-check .templates-section {
  margin-top: 52px;
  padding: 0px 16px;
  padding-bottom: 120px;
}

#service-template-check .type-nav {
  position: sticky;
  top: 62px;
  background: var(--main-bg-color);
  font-family: "Noto Sans KR-Medium";
}
#service-template-check .type-nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  gap: 24px;
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.5);
}
#service-template-check .type-nav li {
  /* width: 100%; */
  width: 70px;

  max-width: 100px;
  padding: 8px 0px;

  text-align: center;
  font-size: 16px;
}

#service-template-check .type-nav li.active {
  color: var(--default-color);
  border-bottom: 2px solid var(--default-color);
}

#service-text-uploads {
  padding: 0 16px;
}
#service-text-uploads > main {
  margin-top: 24px;
  margin-bottom: 8px;
  background: var(--dark-bg-color);

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  border-radius: 12px;
  padding: 14px;

  box-sizing: border-box;
  width: 100%;
}

#service-text-uploads > main .button_wraps {
  margin-top: 20px;
  display: flex;
  gap: 16px;
  height: 40px;
  margin-bottom: 30px;
}

#service-text-uploads > main .button_wraps button {
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 4px;
  border: 1px solid var(--Button-Default-BG, #339dff);
  background: #214369;

  color: var(--Button-Default-BG, #339dff);
  text-align: center;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
}

#service-text-uploads > main .button_wraps .reset-btn {
  border-radius: 4px;
  border: 1px solid var(--Text-light-gray, #d8d8d8);
  background: #484848;
  color: #fff;
}

#service-text-uploads .text-list_article {
  width: 100%;
  height: 96px;
  border-radius: 12px;

  display: flex;
  gap: 8px;

  overflow-x: auto;
  padding: 8px;

  box-sizing: border-box;
  width: 100%;

  background: var(--dark-bg-color);
}

#service-text-uploads .text-list_article img.active {
  border: 4px solid var(--default-color);
}

/* NewEvent.tsx css */
#newEvent {
  color: #fff;
  /* padding-bottom: 120px; */
}

#newEvent .newEvent-mainImage {
  border-radius: 15px;
  border: 1px solid #fff;
  margin: 0 40px 48px 40px;
  position: relative;
}
#newEvent .newEvent-petal {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}
#newEvent .newEvent-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR-bold";
  font-size: 20px;
  line-height: 140%;
  padding-top: 60px;
  padding-bottom: 80px;
}
#newEvent .newEvent-title .newEvent-title-en {
  font-family: "MapoFlowerIsland";
  font-size: 12px;
  color: var(--light-font-color);
  margin-bottom: 24px;
}
#newEvent .newEvent-title p {
  font-family: "Noto Sans KR-Mideum";
  font-size: 14px;
  line-height: 140%;
  color: var(--light-font-color);
  margin-top: 10px;
}

#newEvent .newEvent-sample_btn {
  display: flex;
  width: 90%;
  max-width: 500px;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  margin: auto;

  border-radius: 8px;
  background: #214369;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: var(--default-color);
  font-family: "Pretendard Variable";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */

  margin-top: 8px;
}

#newEvent .newEvent-article {
  margin: 72px 0 72px 0;
}
#newEvent .newEvent-article div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
#newEvent .newEvent-article h3,
#newEvent .newEvent-article p {
  font-family: "Noto Sans KR-Bold";
  font-size: 18px;
  color: #f5f5f7;
  text-align: center;
}
#newEvent .newEvent-article span {
  color: #ffd900;
}
#newEvent .newEvent-article img {
  width: 100%;
  height: auto;
  margin: 10px 0 48px 0;
}

#newEvent .newEvent-feedbacks {
  background-color: #1a1a1a;
}

#newEvent .newEvent-contents {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0px 16px;
  margin-bottom: 40px;
}

#newEvent .contents-title {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 4px;
  margin: 80px 0 40px 0;
  font-family: "Noto Sans KR-Bold";
  font-size: 18px;
}

#newEvent .newEvent-PersonalInfo {
  font-family: "Noto Sans KR-Regular";
  font-size: 14px;
  color: var(--light-font-color);
  text-align: center;
}

#newEvent .newEvent-PersonalInfo .thumbnail {
  width: 100%;
  height: 200px;
  margin-bottom: 0px;
  border-radius: 0 0 15px 15px;
}

/* #newEvent .newEvent-PersonalInfo p {
  max-width: 500px;
  margin: auto;
} */

/* #newEvent .newEvent-PersonalInfo button {
  width: 90%;
  max-width: 500px;
  height: 48px;

  border-radius: 10px;

  background-color: var(--button-main-color);
  color: #ffffff;
} */

.agreement-contents {
  display: flex;
  flex-direction: column;
  align-items: start;
  font-family: "Noto Sans KR-Light";
  color: #fff;

  gap: 16px;
  margin: 24px 0px;
}
.all-agreement {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  padding-left: 8px;
  cursor: pointer;
}
.all-agreement:hover {
  color: #339dff;
}
.all-agreement button {
  width: 20px;
  height: 20px;
  border: solid 1px #8f8f8f;
  border-radius: 50%;
}

.all-agreement button.active {
  background-image: url("@assets/images/service/check-box-blue.svg");
  border: none;
  background-size: contain;
}

.part-agreements {
  display: flex;
  flex-direction: column;
  text-align: start;
  font-size: 13px;
  padding-left: 24px;
  gap: 10px;
}
.part-agreements div {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  transition: color 0.1s;
}

.part-agreements div:hover {
  color: #339dff;
}
.part-agreement {
  width: 14px;
  height: 14px;
}

/* #newEvent .newEvent-PersonalInfo .main-button {
  width: 90%;
  max-width: 500px;
  height: 48px;
  border-radius: 10px;
  background-color: var(--button-main-color);
  color: #ffffff;
} */
#newEvent .bottom-order-section {
  width: 100%;
  max-width: 500px;
  padding: 24px 16px 48px 16px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #222;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0;
  transition: bottom 0.3s ease-in-out; /* 애니메이션 효과 */
  z-index: 999;

  gap: 16px;
  flex-direction: column;
}

#newEvent .main-button {
  width: 90%;
  max-width: 500px;
  height: 48px;
  border-radius: 10px;
  background-color: var(--button-main-color);
  color: #ffffff;
}

#newEvent .bottom-button {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
#newEvent .bottom-button button {
  width: 100%;
  max-width: 500px;
  height: 48px;
  border-radius: 10px;
  background-color: var(--button-main-color);
  color: #ffffff;
}
#newEvent .bottom-button .announcement-text {
  font-family: "Noto Sans KR-light";
  font-size: 12px;
  text-align: center;
  color: var(--light-font-color);
}
